@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding: 128px 0;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background: $c-accent-1;

  .container {
    width: 100%;
    margin: 0 $size-min-screen-border-gap;
    max-width: $size-container-width;
    position: relative;
  }

  .header {
    width: 100%;
    padding-bottom: 24px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      text-align: left;
    }

    .button {
      padding: 6px 16px;

      display: flex;
      align-items: center;
      gap: 16px;

      background: inherit;
      border-radius: 24px;
      border: 1px solid $c-black;

      @include hover;

      .text {
        @include font-base (18px, 500, 100%, 0.05em, uppercase);
        white-space: nowrap;
        color: $c-black;
        @include hover;
      }

      .icon {
        @include square-icon (24px, $c-black);
      }

      &:hover {
        background: $c-black;
        .text { color: $c-white; }
        .icon path { fill: $c-white; }
      }
    }
  }

  .content {
    display: flex;
    scroll-behavior: smooth;

    .imageWrap {
      width: auto;
      height: auto;
      flex-shrink: 0;
      position: relative;
      margin-right: 44px;
      @include hover;

      &:last-child {
        margin-right: 0;
      }

      .image {
        height: 300px;
        border-radius: 12px;
      }

      .filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background: rgba($c-accent-2, 0.25);
        @include hover;
      }

      &:hover .filter {
        opacity: 0;
      }
    }
  }

  @media screen and (max-width: $size-4-column-width) {
    .header {
      padding: 0 $size-min-screen-border-gap;
      padding-bottom: 32px;
    }

    .content .imageWrap:first-child {
      margin-left: 64px;
    }

    .content .imageWrap:last-child {
      margin-right: 64px;
    }
  }

  @media screen and (max-width: $size-3-column-width) {
    padding: 80px 0;

    .header {
      .button {
        padding: 4px 12px;
        .text { font-size: 16px; }
        .icon { @include square-icon (22px, $c-black); }
      }
    }
  }

  @media screen and (max-width: 670px) {
    .container {
      padding-bottom: 64px;

      .header {
        .title {
          text-align: center;
        }

        .button {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .content .imageWrap {
        margin-right: 24px;
      }
    }
  }
}