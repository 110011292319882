@import 'src/resources/styles';

@mixin resizeChange ($gap, $iconSize, $fontSize) {
  gap: $gap;

  .icon {
    @include square-icon ($iconSize, $c-gray-2);
  }

  .text {
    font-size: $fontSize;
  }
}


.wrap {
  max-width: 500px;
  width: 100%;

  display: flex;
  align-items: center;
  gap: 16px;

  .icon {
    @include square-icon (32px, $c-gray-2);
  }

  .text {
    @include font-base (14px, 500, 130%, 0.05em, none);
    text-align: justify;
    color: $c-gray-4;
  }

  @media screen and (max-width: $size-3-column-width) {
    @include resizeChange(12px, 24px, 14px);
  }

  @media screen and (max-width: $size-2-column-width) {
    @include resizeChange(8px, 24px, 12px);
  }
}