@import 'src/resources/styles';

.form {
  display: flex;
  flex-direction: column;
  gap: 36px;

  @media screen and (max-width: $size-2-column-width) {
    gap: 24px;
  }
  
  .submitButton {
    width: 100%;
    height: 48px;
    margin-top: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    border: none;
    border-radius: 24px;
    background: $c-accent-4;
    outline: none !important;

    @include hover;

    span {
      @include font-base (16px, 500, 100%, 0.1em, none);
      color: $c-white;
    }

    .icon {
      @include square-icon (24px, $c-white);
    }

    &:hover {
      gap: 24px;
    }
  }
}