@import 'src/resources/styles';

.wrap {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  .outletWrap {
    flex-grow: 1;
    width: 100%;
    max-width: $size-container-width;
    padding: 0 $size-min-screen-border-gap;

    .headerWrap {
      position: sticky;
      top: 0;
      z-index: $zi-fixed-elements-header;

      display: flex;
      justify-content: center;

      background: $c-white;
    }
  }
}