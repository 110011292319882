@import 'src/resources/styles';

.wrap {
  width: 100%;

  display: grid;
  gap: 10px 16px;
  grid-template-areas: 'image info'
                       'image control';
  grid-template-columns: auto 1fr;

  .imageWrap {
    grid-area: image;
    
    width: 110px;
    max-width: 110px;
    height: 110px;
    max-height: 110px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    background: $c-accent-1;
    border-radius: 8px;

    .image {
      object-fit: contain;
      max-width: 90%;
      max-height: 90%;
    }
  }

  .infoWrap {
    grid-area: info;

    width: 100%;

    display: flex;
    justify-content: space-between;
    gap: 8px;

    .info {
      padding-top: 4px;

      display: flex;
      flex-direction: column;
      gap: 12px;

      .title {
        @include font-base (16px, 500, 110%, 0.05em, uppercase);
        color: $c-black;
      }

      .props {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    .removeControl {
      .removeButton {
        background: inherit;
        border: none;
        @include hover;

        .icon {
          @include square-icon (32px, $c-gray-2);
        }

        &:hover .icon {
          @include square-icon (32px, $c-gray-3);
        }
      }
    }
  }

  .controlWrap {
    grid-area: control;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;

    .price {
      padding-bottom: 2px;
      @include font-base (20px, 700, 100%, -0.05em, uppercase);
      color: $c-highlight-red;

      span {
        padding-left: 3px;
        font-size: 14px;
      }
    }

    .amountControl {
      display: flex;
      align-items: center;
      gap: 16px;

      background: $c-accent-1;
      border-radius: 6px;

      .amount {
        @include font-base (14px, 600, 100%, 0, none);
        color: $c-black;
      }

      .button {
        padding: 6px;
        padding-top: 4px;
        padding-bottom: 1px;
        background: inherit;
        border: none;
        border-radius: 8px;
        @include hover;

        .icon {
          @include square-icon (24px, $c-black);
        }

        &:disabled {
          cursor: default;

          .icon {
            @include square-icon (24px, $c-gray-2);
          }
        }
      }
    }
  }

  @media screen and (max-width: $size-2-column-width) {
    grid-template-areas: 'image info'
                         'control control';
  }

  @media screen and (max-width: calc($size-1-column-width * 1.5)) {
    row-gap: 16px;

    .controlWrap {
      padding-left: 6px;
      justify-content: space-between;
    }
  }
}