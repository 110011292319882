@import 'src/resources/styles';

@mixin leftBorderRounded ($radius) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@mixin rightBorderRounded ($radius) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

.wrap {
  width: 100%;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  .block {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .label {
      width: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      padding: 2px 16px;
      padding-left: 50px;

      background: $c-gray-0;
      @include rightBorderRounded(16px);

      .icon {
        @include square-icon (24px, $c-black);
      }

      h3 {
        @include font-additional (20px, 500, 100%, 0.1em, none);
        color: $c-black;
      }
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .left,
      .right {
        display: flex;
        flex-direction: column;
      }

      .left {
        padding-left: 50px;
      }

      .right {
        align-items: flex-end;
      }

    }
  }

  .descBlock {
    .content {
      .left {
        gap: 12px;

        .descText {
          max-width: 370px;
          @include font-base (16px, 500, 110%, 0.05em, none);
          text-align: justify;
          color: $c-black;

          span {
            font-weight: 700;
            white-space: nowrap;
          }
        }
      }

      .right {
        gap: 16px;

        .orderNumber {
          position: relative;
          width: 100%;
          max-width: 370px;
          padding: 14px 12px;

          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 4px;

          background: $c-gray-0;
          @include leftBorderRounded(16px);

          .deco {
            position: absolute;
            left: 16px;
            top: 8px;
            @include font-additional (52px, 500, 100%, 0, uppercase);
            color: $c-gray-1;
          }

          .text {
            @include font-additional (14px, 500, 100%, 0.05em, uppercase);
            color: $c-black;
          }

          .number {
            @include font-additional (24px, 500, 100%, 0.05em, uppercase);
            color: $c-black;
          }
        }

        .aboutContract {
          max-width: 370px;
          @include font-base (12px, 500, 110%, 0.05em, none);
          text-align: right;
          color: $c-gray-3;
        }
      }
    }
  }

  .docsBlock {
    .content {
      .left {
        gap: 8px;

        .docAbout {
          max-width: 320px;
          @include font-base (14px, 500, 120%, 0.05em, none);
          text-align: justify;
          color: $c-black;

          span {
            font-weight: 700;
            color: $c-highlight-red;
            white-space: nowrap;
          }
        }
      }

      .right {
        .downloadButton {
          width: 100%;
          max-width: 370px;
          padding: 12px;

          display: flex;
          align-items: center;
          gap: 24px;

          background: $c-white;
          @include leftBorderRounded(16px);
          border: 2px dashed $c-highlight-red;

          @include hover;

          .icon {
            @include square-icon (64px, $c-highlight-red);
          }

          .text {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
              @include font-base (26px, 700, 100%, 0.05em, uppercase);
              text-align: left;
              color: $c-highlight-red;
            }

            .description {
              max-width: 240px;
              @include font-base (14px, 500, 110%, 0.05em, none);
              text-align: left;
              color: $c-gray-4;
              @include hover;
            }
          }

          &:hover {
            background: rgba($c-highlight-red, 0.1);
            .text .description {
              color: $c-black;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $size-3-column-width) {
    .block {
      .content {
        column-gap: 44px;
        .left {
          padding-left: 0;
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    gap: 32px;

    .block .content .left {
      padding-left: 50px;
    }

    .descBlock .content {
      display: flex;
      flex-direction: column-reverse;
      gap: 24px;

      .right {
        align-items: flex-start;

        .orderNumber {
          align-items: flex-start;
          @include rightBorderRounded(16px);

          .deco {
            left: auto;
            right: 16px;
          }
        }

        .aboutContract {
          margin-left: 50px;
          text-align: justify;
        }
      }
    }

    .docsBlock .content {
      display: flex;
      flex-direction: column-reverse;
      gap: 24px;

      .right {
        align-items: flex-start;

        .downloadButton {
          @include rightBorderRounded(16px);
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    * {
      text-align: left !important;
    }
    .block .content .left {
      padding-left: 0;
    }

    .descBlock .content .right .aboutContract {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 420px) {
    .block .label {
      padding-left: 12px;
    }

    .descBlock .content .right .orderNumber .deco {
      display: none;
    }

    .docsBlock .content .right .downloadButton {
      gap: 16px;

      .icon {
        @include square-icon (52px, $c-highlight-red);
      }

      .text {
        .title {
          font-size: 20px;
        }

        .description {
          font-size: 12px;
        }
      }
    }
  }
}