@import 'src/resources/styles';

@mixin cardHover ($bg, $circle, $icon, $title, $description) {
  background: $bg;

  .iconWrap {
    background: $circle;
    .icon path { fill: $icon; }
  }

  .text {
    .title { color: $title; }
    .description { color: $description; }
  }
}

@mixin useHorizontal {
  width: 100%;
  flex-direction: column;
  gap: 12px;
  
  .card {
    max-width: none;
    width: 100%;
    flex-direction: row;
    gap: 16px;
    
    .iconWrap {
      width: 70px;
      min-width: 70px;
      height: 70px;
      min-height: 70px;

      border-radius: 35px;

      .icon {
        @include square-icon (44px, $c-gray-2);
      }
    }

    .text {
      align-items: flex-start;

      .title, .description { text-align: left; }
    }

    &.card_telegram {
      .iconWrap {
        padding-right: 6px;
      }
    }
  }
}

.wrap {
  display: flex;
  align-items: stretch;
  gap: 32px;

  .card {
    width: 100%;
    max-width: 230px;
    padding: 16px 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    background: $c-gray-0;
    border-radius: 20px;

    @include hover;

    .iconWrap {
      width: 160px;
      min-width: 160px;
      height: 160px;
      min-height: 160px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: $c-white;
      border-radius: 80px;
      @include hover;

      .icon {
        @include square-icon (100px, $c-gray-2);

        path {
          @include hover;
        }
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;

      .title {
        @include font-additional (18px, 600, 100%, 0.1em, uppercase);
        text-align: center;
        color: $c-black;
        @include hover;
      }

      .description {
        @include font-base (14px, 500, 100%, 0.05em, none);
        text-align: center;
        color: $c-gray-4;
        @include hover;
      }
    }

    &.card_telegram {
      .iconWrap {
        padding-right: 16px;
      }
    }

    &.card_telegram:hover {
      @include cardHover (#D9E6F1, #F5FAFF, #9FBBE5, #262B5B, #516791);
    }

    &.card_whatsapp:hover {
      @include cardHover (#CDF0CC, #EFFFEC, #9CC68E, #295C17, #3B8F2D);
    }
  }

  @media screen and (max-width: $size-3-column-width) and (min-width: 800px) {
    @include useHorizontal;
  }

  @media screen and (max-width: 800px) {
    .card {
      max-width: none;
    }
  }

  @media screen and (max-width: 480px) {
    @include useHorizontal;
  }
}