@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding: 128px $size-min-screen-border-gap;
  position: relative;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;

  .content {
    display: grid;
    grid-template-areas: 'exp adv1 adv2 adv3'
                         '.   btn  btn  btn';
    gap: 32px 44px;
    z-index: 1;
    
    .experience {
      grid-area: exp;

      width: 260px;
      margin-right: 70px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .number {
        grid-area: number;
        @include font-additional (200px, 600, 100%, 0, uppercase);
        color: $c-highlight-red;
      }

      .title {
        grid-area: title;
        @include font-additional (24px, 600, 100%, 0, uppercase);
        text-align: center;
        color: $c-black;
      }

      .subtitle {
        grid-area: subtitle;
        max-width: 220px;
        @include font-base (16px, 500, 110%, 0, none);
        text-align: center;
        color: $c-black;
      }
    }

    .advatage {
      width: 260px;
      padding: 16px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;

      background: $c-gray-0;
      border-radius: 16px;

      .icon {
        @include square-icon (128px, $c-gray-2);
        cursor: default;
        path { cursor: default; }
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .title {
          @include font-additional (19px, 600, 100%, 0.05em, none);
          text-align: center;
          color: $c-black;
        }

        .subtitle {
          @include font-base (14px, 500, 100%, 0.05em, none);
          text-align: center;
          color: $c-gray-4;
        }
      }

      .advantage-1 { grid-area: adv1; }
      .advantage-2 { grid-area: adv2; }
      .advantage-3 { grid-area: adv3; }
    }

    .button {
      grid-area: btn;
      background: inherit;

      &:hover {
        background: $c-black;
      }
    }
  }

  .deco {
    * {
      position: absolute;
      opacity: 0.4;
    }

    .deco1 {
      left: 50%;
      top: 54%;
      transform: translateX(min(-660px, -46vw));
    }

    .deco2 {
      left: 50%;
      top: 10%;
      transform: translateX(min(500px, 40vw));
    }
  }

  @media screen and (max-width: 1250px) {
    gap: 44px;

    .content {
      grid-template-areas: 'exp  adv1'
                           'adv2 adv3'
                           'btn  btn';
      gap: 32px;

      .experience {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 600px) and (min-width: 400px) {
    gap: 44px;

    .content {
      width: 100%;
      grid-template-areas: 'exp' 'adv1' 'adv2' 'adv3' 'btn';
      justify-content: center;
      gap: 16px;

      .experience {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;

        display: grid;
        grid-template-areas: 'number .'
                             'number title'
                             'number subtitle'
                             'number .';
        grid-template-columns: auto auto;
        justify-content: center;
        column-gap: 24px;

        .number {
          font-size: 100px;
        }

        .title, .subtitle {
          text-align: left;
        }
      }

      .advatage {
        width: 370px;
        padding-right: 44px;
        flex-direction: row;
        gap: 24px;
        background: rgba($c-white, 0);

        .icon {
          @include square-icon (80px, $c-gray-2);
        }

        .text {
          align-items: flex-start;
          gap: 6px;

          .title, .subtitle {
            text-align: left;
          }
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    .content {
      width: 100%;
      grid-template-areas: 'exp' 'adv1' 'adv2' 'adv3' 'btn';
      justify-content: center;
      gap: 16px;

      .experience {
        margin-bottom: 24px;
        gap: 4px;

        .number { font-size: 160px; }
        .title { font-size: 20px; }
      }

      .advatage {
        gap: 24px;

        .icon {
          @include square-icon (100px, $c-gray-2);
        }
      }
    }
  }
}