@import 'src/resources/styles';

.loader {
  height: 20vh;
}

.description {
  width: 100%;
  margin-bottom: 44px;
  padding: 12px;
  padding-right: 20px;

  display: flex;
  align-items: flex-start;
  gap: 16px; 

  background: $c-gray-0;
  border-radius: 12px;

  .icon {
    @include square-icon (24px, $c-gray-3);
  }

  .text {
    padding-top: 1px;
    @include font-base (14px, 500, 120%, 0.05em, none);
    color: $c-black;

    .link {
      padding: 0 4px;
      font-weight: 700;
      color: $c-highlight-red;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 36px;

  @media screen and (max-width: $size-2-column-width) {
    gap: 24px;
  }
  

  .submitButton {
    width: 100%;
    height: 48px;
    margin-top: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    border: none;
    border-radius: 24px;
    background: $c-accent-4;
    outline: none !important;

    @include hover;

    span {
      @include font-base (16px, 500, 100%, 0.1em, none);
      color: $c-white;
    }

    .icon {
      @include square-icon (24px, $c-white);
    }

    &:hover {
      gap: 24px;
    }
  }
}