@import 'src/resources/styles';

@keyframes slide {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@mixin resize ($padding, $gap, $icon) {
  .wrap .scroll .modal {
    padding: $padding;
    gap: $gap;

    .header .closeButton .icon { @include square-icon ($icon, $c-gray-2); }
    .header .closeButton:hover .icon { @include square-icon ($icon, $c-gray-4); }
  }
}

.wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $zi-modal-window;

  padding: $size-min-screen-border-gap;
  overflow-y: auto;
  overflow-x: hidden;

  background: rgba($c-black, 0.8);

  .scroll {
    position: absolute;
    width: calc(100% - $size-min-screen-border-gap * 2);
    min-height: 80vh;
    padding: 80px 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
      width: 100%;
      max-width: 1000px;
      padding: 44px;

      display: flex;
      flex-direction: column;
      gap: 64px;

      background: $c-white;
      border-radius: 16px;

      animation: slide 0.5s ease;

      .header {
        display: flex;
        flex-wrap: nowrap;
        gap: 16px;

        .title {
          padding-top: 6px;
        }

        .closeButton {
          display: flex;
          align-items: flex-start;
          justify-content: center;

          border: none;
          background: inherit;
          outline: none !important;
          @include hover;

          .icon {
            @include square-icon (50px, $c-gray-2);
            
            path {
              @include hover;
            }
          }

          &:hover {
            .icon {
              @include square-icon (50px, $c-gray-4);
            }
          }
        }
      }
    }

  }
}

@media screen and (max-width: $size-3-column-width) {
  @include resize(32px, 48px, 36px);
  
  .wrap .scroll {
    padding: 32px 0;
  }
}

@media screen and (max-width: $size-2-column-width) {
  @include resize(20px, 32px, 36px);
}