@import 'src/resources/styles';

.wrap {
  width: 100%;

  .headerWrap {
    width: 100%;
    padding: 0 $size-min-screen-border-gap;
    display: flex;
    justify-content: center;
    background: $c-white;

    position: fixed;
    top: 0;
    z-index: $zi-fixed-elements;

    & > * {
      width: 100%;
      max-width: $size-container-width;
      background: rgba($c-white, 0);
    }
  }
}