@import 'src/resources/styles';

@mixin resizeChange ($gap, $iconSize, $fontSize) {
  gap: $gap;

  .link {
    font-size: $fontSize;
  }

  .icon {
    @include square-icon ($iconSize, $c-highlight-red);
  }
}


.wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;

  padding-left: 4px;

  .linkWrap {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .icon {
    @include square-icon (20px, $c-highlight-red);
  }

  .link {
    @include font-base (16px, 700, 90%, 0.05em, uppercase);
    color: $c-highlight-red;
    text-decoration: none;
  }

  @media screen and (max-width: $size-3-column-width) {
    @include resizeChange(2px, 16px, 14px);
  }

  @media screen and (max-width: $size-2-column-width) {
    @include resizeChange(2px, 14px, 12px);
  }
}