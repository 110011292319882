@import 'src/resources/styles';

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 52px;

  .panel {
    margin-left: 50px;
    max-width: 1200px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 24px;

    .params {
      display: flex;
      align-items: stretch;
      gap: 24px;

      .item {
        width: 260px;
        padding: 16px 12px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;

        background: $c-gray-0;
        border-radius: 16px;

        .icon {
          @include square-icon (128px, $c-gray-3);
        }

        .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          text-align: center;

          .title {
            @include font-additional (20px, 600, 100%, 0.05em, none);
            color: $c-highlight-red;
          }

          .subtitle {
            @include font-base (14px, 500, 100%, 0.05em, none);
            color: $c-gray-4;
          }
        }
      }
    }

    .action {
      width: 100%;
      max-width: 520px;
      padding: 24px 32px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 32px;

      background: $c-accent-1;
      border: none;
      border-radius: 16px;
      outline: none !important;
      text-align: left;

      @include hover;

      .text {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
          @include font-additional (36px, 500, 100%, 0, none);
          color: $c-black;
        }

        .subtitle {
          @include font-additional (16px, 500, 110%, 0.05em, none);
          color: $c-accent-4;
        }
      }

      .pseudoButton {
        width: 100%;
        height: 52px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 26px;
        border: 1px dashed $c-accent-4;

        @include font-additional (20px, 500, 100%, 0.05em, none);
        color: $c-accent-4;

        @include hover;

        &:hover {
          background: $c-accent-4;
          border-style: solid;
          color: $c-white;
        }
      }
    }
  }

  .services {
    margin-top: 30px;
    margin-left: 50px;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    gap: 32px;

    .column {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .item {
        display: flex;
        align-items: flex-start;
        gap: 16px;
  
        .icon {
          @include square-icon (24px, $c-black);
        }
  
        .content {
          width: 100%;
          max-width: 480px;
  
          display: flex;
          flex-direction: column;
          gap: 12px;
  
          .title {
            @include font-base (18px, 600, 100%, 0.05em, none);
            color: $c-black;
          }
  
          .description {
            @include font-base (16px, 500, 110%, 0.05em, none);
            text-align: justify;
            color: $c-gray-4;
  
            a {
              font-weight: 600;
              color: $c-highlight-red;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $size-4-column-width) {
    .panel, .services {
      margin-left: 12px;
    }
  }

  @media screen and (max-width: 1170px) {
    .panel {
      flex-direction: column;

      .params .item {
        width: 100%;
        flex-direction: row;
  
        .icon {
          @include square-icon-size (64px);
        }

        .text {
          align-items: flex-start;
          text-align: left;
        }
      }

      .action {
        max-width: 100%;
      }
    }

    .services {
      flex-direction: column;

      .column .item .content {
        max-width: 640px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    gap: 32px;
    
    .panel .params {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 610px) {
    .panel,
    .services {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 440px) {
    .panel {
      .action {
        .text {
          gap: 8px;
          .title {
            font-size: 28px;
          }
          .subtitle {
            font-size: 14px;
          }
        }
        .pseudoButton {
          font-size: 16px;
        }
      }
    }

    .panel .params {
      .item{
        align-items: flex-start;
        gap: 24px;
  
        .icon {
          @include square-icon-size (40px);
        }
      }
    }
  }
}