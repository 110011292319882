@import 'src/resources/styles';

.blur {
  filter: blur(10px);
  transition: filter 0.3s linear;
}

.clear {
  filter: blur(0);
  transition: filter 0.3s linear;
}