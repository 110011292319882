@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding-top: 24px;
  padding-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  border-bottom: 1px solid $c-accent-2;

  .logo {
    display: flex;
    align-items: center;
    gap: 12px;

    .burgerButton {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 1px;

      background: inherit;
      border: none;

      .icon {
        @include square-icon (32px, $c-black);
      }

      display: none;
    }

    .link {
      display: flex;
      align-items: center;
      gap: 16px;

      @include font-additional (24px, 600, 100%, 0.05em, uppercase);
      color: $c-black;

      .icon {
        @include square-icon-size (24px);
      }
    }
  }

  .menu {
    display: flex;
    align-items: center;
    gap: 16px;

    li a {
      padding: 0 16px;

      @include font-base (16px, 500, 90%, 0.05em, none);
      color: $c-black;

      border: 1px solid $c-black;
      border-radius: 12px;

      @include hover;

      &:hover {
        border-color: $c-accent-4;
        color: $c-accent-4;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 16px;

    li, li a, li button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    li button {
      background: inherit;
      border: none;
      outline: none !important; 
    }

    li {
      svg {
        @include square-icon (26px, $c-black);
      }

      &:hover svg path {
        fill: $c-accent-4;
      }
    }

    .basketButton {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 3px 12px;
        border-radius: 12px;
        background: $c-accent-2;

        @include font-additional (14px, 700, 100%, 0, none);
        color: $c-accent-4;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .logo .burgerButton {
      display: flex;
    }

    .menu {
      display: none;
    }
  }

  @media screen and (max-width: 370px) {
    .logo {
      gap: 8px;
    }

    .buttons {
      gap: 12px;

      .userButtonLI,
      .basketButton span {
        display: none;
      }
    }
  }
}