@import 'src/resources/styles';

.wrap {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .name {
    @include font-base (12px, 700, 14px, 0, uppercase);
    color: $c-accent-4;
  }

  .value {
    @include font-base (20px, 700, 100%, 0, uppercase);
    color: $c-black;

    .measureUnit {
      @include font-base (12px, 700, 100%, 0, uppercase);
    }
  }

  &.small {
    gap: 2px;

    .name {
      font-size: 10px;
    }
  }
}