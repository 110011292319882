@import 'src/resources/styles';

.wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  gap: 16px;

  @include hover;

  .imgWrap {
    width: 100%;
    max-height: 300px;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    .img {
      object-fit: contain;
      max-width: 100%;
      max-height: 300px;
    }
  }

  .content {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .textWrap {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 6px;

      .subtitle {
        @include font-base (16px, 500, 100%, 0.05em, none);
        color: $c-accent-4;
      }
    }

    .pseudoButton {
      width: 100%;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      border: 1px solid $c-accent-4;
      border-radius: 16px;

      @include hover;

      .btnText {
        @include font-base (16px, 500, 90%, 0.1em, none);
        color: $c-accent-4;
      }

      .btnIcon {
        @include square-icon (24px, $c-accent-4);
      }
    }
  }

  &:hover {
    .pseudoButton {
      gap: 8px;
      border-color: $c-black;

      .btnText {
        color: $c-black;
      }

      .btnIcon {
        @include square-icon (24px, $c-black);
      }
    }
  }
}