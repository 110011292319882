@import 'src/resources/styles';

.wrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .number {
      display: inline-flex;
      padding: 6px 24px;

      background: $c-black;
      border-radius: 12px;

      @include font-additional (20px, 500, 100%, 0.05em, uppercase);
      color: $c-white;
    }

    .date {
      @include font-additional (16px, 600, 100%, 0.05em, none);
      color: $c-black;
    }
  }

  .content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 12px;

    .downloadInvoiceButton,
    .notFileLabel {
      max-width: 300px;
      width: 100%;
      padding: 6px;

      display: flex;
      align-items: center;
      gap: 20px;

      border-radius: 16px;
      border: 1px solid $c-highlight-red;

      .icon {
        @include square-icon (64px, $c-highlight-red);
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
          @include font-base (20px, 700, 100%, 0.1em, uppercase);
        }

        .description {
          @include font-base (12px, 500, 100%, 0.05em, none);
        }
      }
    }

    .downloadInvoiceButton {
      text-align: left;
      background: inherit;
      border-color: $c-highlight-red;
      outline: none !important;

      @include hover;

      &:hover {
        background: rgba($c-highlight-red, 0.1);
        .text .description {
          @include hover;
          color: $c-black;
        }
      }

      .icon path {
        fill: $c-highlight-red;
      }

      .text {
        .title { color: $c-highlight-red; }
        .description { color: $c-gray-4; }
      }
    }

    .notFileLabel {
      border-color: $c-gray-3;

      .icon path {
        fill: $c-gray-3;
      }

      .text {
        .title {
          font-size: 16px;
          color: $c-gray-4;
        }

        .description {
          color: $c-gray-4;
        }
      }
    }

    .params {
      padding-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 16px;

      .field {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        .label {
          @include font-base (12px, 700, 100%, 0.05em, uppercase);
          color: $c-gray-3;
        }

        .value {
          @include font-base (12px, 600, 100%, 0.05em, none);
          color: $c-black;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .content {
      .params {
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
      }
    }
  }

  @media screen and (max-width: 470px) {
    .content {
      width: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 8px;
  
      .downloadInvoiceButton,
      .notFileLabel {
        max-width: 100%;
        flex-grow: 1;
      }
  
      .params {
        width: 100%;
        padding: 0 8px; 
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
  
        .field {
          padding-top: 8px;
          flex-direction: row;
          gap: 6px;
        }
      }
    }
  }

  @media screen and (max-width: 390px) {
    .content {
      .params {
        .field {
          flex-direction: column;
          align-items: flex-end;
          gap: 2px;
        }
      }
    }
  }
}