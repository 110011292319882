@import 'src/resources/styles';

.wrap {
  width: 100%;
  margin: 96px 0;

  display: flex;
  flex-direction: column;
  gap: 80px;

  .loader {
    width: 100%;
    height: 60vh;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .subtitleRow {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 24px;

      padding-top: 8px;

      .subtitle {
        max-width: 660px;
        padding-left: 4px;

        @include font-base (18px, 500, 110%, 0.05em, none);
        color: $c-accent-4;
      }

      .amountGroups {
        padding-bottom: 6px;
        @include font-base (18px, 500, 110%, 0.05em, uppercase);
        color: $c-accent-4;
        white-space: nowrap;
      }

      @media screen and (max-width: $size-2-column-width) {
        padding-top: 0;

        .subtitle {
          font-size: 16px;
        }

        .amountGroups {
          display: none;
        }
      }
    }
  }

  .productGroups {
    width: 100%;
    
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 80px 64px;

    @media screen and (max-width: $size-4-column-width) {
      gap: 60px 40px;
    }

    @media screen and (max-width: $size-3-column-width) {
      gap: 48px 32px;
    }
  }

}