// colors

$c-black: #333333;
$c-white: #FFFFFF;

$c-accent-1: #F8F5F0;
$c-accent-2: #F0EBE0;
$c-accent-3: #D3C5AC;
$c-accent-4: #938264;

$c-gray-0: #F5F5F5;
$c-gray-1: #ECECEC;
$c-gray-2: #D7D7D7;
$c-gray-3: #B9B9B9;
$c-gray-4: #B9B9B9;

$c-highlight-red: #9F4545;
$c-highlight-red-hover: #8a4040;

$c-highlight-green: #275938;
$c-highlight-green-hover: #143720;


// fonts

@mixin __font ($fz, $fw, $lh, $ls, $tt) {
  font-size: $fz;
  font-weight: $fw;
  line-height: $lh;
  letter-spacing: $ls;
  text-transform: $tt;
  color: $c-black;
}

@mixin font-base ($fz, $fw, $lh, $ls, $tt) {
  @include __font ($fz, $fw, $lh, $ls, $tt);
  font-family: 'Manrope', sans-serif;
}

@mixin font-additional ($fz, $fw, $lh, $ls, $tt) {
  @include __font ($fz, $fw, $lh, $ls, $tt);
  font-family: 'Lora', serif;
}


// hover 

@mixin hover {
  transition: all 0.4s ease;
  cursor: pointer;
}


// sizes

$size-container-width: 1320px;

$size-1-column-width: calc($size-container-width / 4 - 1px);
$size-2-column-width: calc($size-1-column-width * 2 - 1px);
$size-3-column-width: calc($size-1-column-width * 3 - 1px);
$size-4-column-width: calc($size-container-width - 1px);

$size-min-screen-border-gap: 16px;

@mixin square-icon-size ($size) {
  min-width: $size;
  width: $size;
  min-height: $size;
  height: $size;
}

@mixin square-icon ($size, $color) {
  @include square-icon-size($size);
  @include hover;

  path {
    fill: $color;
    @include hover;
  }
}


// z-indexes

$zi-fixed-elements: 50;
$zi-fixed-elements-header: 51;
$zi-modal-window: 100;
$zi-tooltip: 150;


// scrollbar

@mixin scrollbar ($track-color) {
  &::-webkit-scrollbar-track {
    border: 1px solid $c-white;
    background-color: inherit;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: inherit;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $track-color;
    border-radius: 8px;  
  }
}
