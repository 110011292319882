@import 'src/resources/styles';

.wrap {
  min-width: 300px;
  min-height: 200px;
  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  background: $c-white;
  border: 2px dashed $c-accent-2;
  border-radius: 12px;

  @include hover;

  .topRow {
    width: 100%;

    display: flex;
    justify-content: space-between;

    .orderNumber {
      @include font-base (64px, 700, 100%, 0.05em, uppercase);
      color: $c-accent-2;
      @include hover;
    }

    .arrowIcon {
      @include square-icon (64px, $c-accent-2);
      transform: rotate(-45deg) translateX(16px);
      @include hover;
    }
  }

  .content {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 6px;

    .title {
      @include font-base (28px, 700, 100%, 0.05em, uppercase);
      color: $c-black;
    }

    .description {
      @include font-base (12px, 500, 100%, 0.05em, none);
      color: $c-accent-3;
      @include hover;
    }
  }

  &:hover {
    background: $c-accent-1;
    border-color: $c-accent-3;

    .topRow {
      .orderNumber {
        color: $c-accent-3;
      }

      .arrowIcon {
        @include square-icon(64px, $c-accent-3);
        transform: translateX(4px);
      }
    }

    .content {
      .description {
        color: $c-accent-4;
      }
    }
  }
}