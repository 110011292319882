@import 'src/resources/styles';

.wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  .iconWrap {
    width: 32px;
    min-width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: $c-accent-4;
    border-radius: 4px;
  }

  .icon {
    @include square-icon (24px, $c-white);
  }

  .text {
    @include font-base (16px, 500, 100%, 0, none);
    color: $c-accent-4;
  }
}