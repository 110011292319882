@import 'src/resources/styles';

.wrap {
  width: 100%;
  max-width: 800px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  .content {
    display: flex;
    align-items: center;
    gap: 32px;

    .icon {
      @include square-icon (132px, $c-gray-2);
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 12px;

      h3 {
        @include font-additional (40px, 500, 100%, 0.05em, none);
        color: $c-black;
      }

      p {
        @include font-base (24px, 500, 105%, 0.05em, none);
        color: $c-gray-3;
      }
    }
  }

  .buttonWrap {
    width: 100%;
    padding: 0 24px;
  }
}

@media screen and (max-width: $size-3-column-width) {
  .wrap {
    max-width: 650px;
  }

  .wrap .content .icon {
    @include square-icon (100px, $c-gray-2);
  }

  .wrap .content .text {
    h3 { font-size: 32px; }
    p { font-size: 20px; }
  }
}

@media screen and (max-width: $size-2-column-width) {
  .wrap .content .icon {
    @include square-icon (80px, $c-gray-2);
  }

  .wrap .content .text {
    h3 { font-size: 24px; }
    p { font-size: 16px; }
  }

  .wrap .buttonWrap {
    padding: 0;
  }
}

@media screen and (max-width: 550px) {
  .wrap .content {
    flex-direction: column;

    .text h3,
    .text p {
      text-align: center;
    }

    .icon {
      @include square-icon (128px, $c-gray-2);
    }
  }
}