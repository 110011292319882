@import 'src/resources/styles';

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header {
    width: 100%;
    padding: 24px;

    display: flex;
    align-items: center;
    gap: 44px;

    background: $c-gray-0;
    border-radius: 32px;

    .icon {
      @include square-icon (80px, $c-gray-3);
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .title {
        @include font-base (32px, 700, 100%, 0.05em, uppercase);
        color: $c-black;
      }

      .description {
        max-width: 360px;
        width: 100%;
        @include font-base (14px, 600, 100%, 0.05em, none);
        color: $c-gray-3;
      }
    }
  }

  .list {
    padding: 0 24px;

    .item {
      padding: 32px 0;
      border-bottom: 1px solid $c-gray-1;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  @media screen and (max-width: 600px) {
    gap: 12px;
    
    .header {
      position: relative;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 24px;

      .text {
        .title {
          padding-left: 48px;
        }
        .description {
          max-width: none;
        }
      }

      .icon {
        position: absolute;
        left: 22px;
        top: 24px;
        @include square-icon (30px, $c-gray-3);
      }
    }

    .list {
      padding: 0 4px;

      .item {
        padding: 24px 0;
      }
    }
  }

  @media screen and (max-width: 410px) {
    .header {
      .icon {
        @include square-icon (24px, $c-gray-3);
      }

      .text {
        .title {
          padding-left: 34px;
          font-size: 24px;
        }
        .description {
          font-size: 12px;
        }
      }
    }
  }
}