@import 'src/resources/styles';

.wrap {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .name {
    @include font-base (14px, 700, 14px, 0, uppercase);
    color: $c-accent-4;
  }

  .value {
    @include font-base (40px, 700, 100%, 0, uppercase);
    color: $c-black;

    .measureUnit {
      @include font-base (16px, 700, 100%, 0, uppercase);
    }
  }


  @media screen and (max-width: $size-3-column-width) {
    .value {
      @include font-base (32px, 700, 100%, 0, uppercase);
      color: $c-black;
    }
  }

  @media screen and (max-width: $size-2-column-width) {
    .name {
      @include font-base (12px, 700, 100%, 0, uppercase);
      color: $c-accent-4;
    }
  
    .value {
      @include font-base (24px, 700, 100%, 0, uppercase);
      color: $c-black;
  
      .measureUnit {
        @include font-base (14px, 700, 100%, 0, uppercase);
      }
    }
  }
}