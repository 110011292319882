@import 'src/resources/styles';

.emptyWrap {
  width: 100%;
  height: 70vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.page {
  width: 100%;
  display: flex;
  justify-content: center;

  .headerWrap {
    width: 100%;
    padding: 0 $size-min-screen-border-gap;
    display: flex;
    justify-content: center;
    background: $c-white;

    position: fixed;
    top: 0;
    z-index: $zi-fixed-elements;

    & > * {
      width: 100%;
      max-width: $size-container-width;
      background: rgba($c-white, 0);
    }
  }
}

.wrap {
  width: 100%;
  max-width: $size-container-width;
  margin: 0 $size-min-screen-border-gap;
  padding-top: 120px;
  padding-bottom: 44px;

  display: flex;
  flex-direction: column;
  gap: 64px;

  .content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 128px;
    align-items: baseline;

    .groups {
      align-self: start;
    }
  
    .controller {
      position: sticky;
      z-index: $zi-fixed-elements;
      top: 128px;
    }
  }

  @media screen and (max-width: $size-4-column-width) {
    gap: 48px;

    .content {
      column-gap: 64px;
    }
  }

  @media screen and (max-width: $size-3-column-width) {
    gap: 32px;

    .content {
      grid-template-columns: 1fr;
      row-gap: 44px;
      align-items: normal;
    }
  }

  @media screen and (max-width: $size-2-column-width) {
    gap: 24px;
  }
}