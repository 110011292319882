@import 'src/resources/styles';

.wrap {
  padding: 80px 0;

  display: flex;
  flex-direction: column;
  gap: 80px;

  .columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    align-items: baseline;

    .leftColumn {
      position: sticky;
      z-index: $zi-fixed-elements;
      top: 128px;
    }

    .rightColumn {
      align-self: start;
    }
  }

  @media screen and (max-width: $size-4-column-width) {
    gap: 64px;
    
    .columns {
      gap: 44px;
    }
  }

  @media screen and (max-width: $size-3-column-width) {
    gap: 44px;

    .columns {
      grid-template-columns: 1fr;

      .leftColumn {
        position: static;
        z-index: 0;
        top: 0;
      }
    }
  }
}