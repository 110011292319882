@import 'src/resources/styles';

.wrap {
  width: 100%;
  margin: 96px 0;

  display: flex;
  flex-direction: column;
  gap: 64px;

  .loader {
    width: 100%;
    height: 60vh;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .groupInfo {
    display: flex;
    flex-direction: column;
    gap: 80px;

    .description {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 50px;

      .descAndProps {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 80px;

        .props {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;

          @media screen and (max-width: $size-2-column-width) {
            gap: 12px;
          }
        }
      }

      .image {
        max-width: 300px;
        max-height: 300px;
      }
    }
  }

  @media screen and (max-width: $size-4-column-width) {
    gap: 40px;

    .groupInfo {
      gap: 64px;

      .description {
        .descAndProps {
          flex-direction: column;
          justify-content: flex-start;
          gap: 32px;
        }

        .image {
          padding: 0 60px;
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    .image {
      display: none;
    }
  }

  .products {
    width: 100%;
    
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 80px 64px;

    @media screen and (max-width: $size-4-column-width) {
      gap: 60px 40px;
    }

    @media screen and (max-width: $size-3-column-width) {
      gap: 48px 32px;
    }
  }

}