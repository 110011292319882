@import 'src/resources/styles';

.wrap {
  width: 100%;
  margin: 64px 0;

  border-top: 1px solid $c-gray-2;

  .loader {
    min-height: 40vh;
  }

  .noResultsLabel {
    width: fit-content;
    margin-top: 12px;
    padding: 6px 24px;

    display: flex;
    align-items: center;
    gap: 24px;

    background: $c-accent-1;
    border-radius: 16px;

    .text {
      @include font-base (14px, 500, 110%, 0.1em, none);
      color: $c-accent-4;
    }

    .icon {
      @include square-icon (44px, $c-accent-3);
    }

    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: column;
      padding: 24px;
      border-radius: 32px;
      .icon { @include square-icon-size (90px); }
    }
  }

  .title {
    padding-top: 44px;
    padding-bottom: 12px;
    @include font-base (14px, 600, 120%, 0.05em, uppercase);
    color: $c-gray-4;
    span { color: $c-black; }
  }

  .list {
    padding: 12px 0;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    align-items: stretch;
    gap: 24px 24px;

    .product {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      .productCard {
        padding: 12px;
        border: 1px dashed $c-accent-3;
        border-radius: 16px;

        img {
          max-height: 200px !important;
          padding-bottom: 12px !important;
        }

        &:hover {
          border-color: $c-accent-4;
        }
      }
    }
  }

  @media screen and (max-width: $size-2-column-width) {
    .list {
      gap: 16px 24px;
    }

    .title {
      padding-top: 24px;
    }
  }
}