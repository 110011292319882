@import 'src/resources/styles';

@keyframes slide {
  from {
    transform: translateX(-280px);
  }

  to {
    transform: translateX(0);
  }
}

.wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100dvh;

  background: rgba(#000, 0.6);
  z-index: $zi-modal-window;

  .window {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: scroll;

    width: 280px;
    min-height: 100dvh;
    padding: 32px 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 64px;

    background: $c-white;
    animation: slide 0.6s ease;

    @include scrollbar($c-gray-3);

    .btnCloseMenu {
      position: absolute;
      top: 26px;
      right: 8px;
      
      background: inherit;
      border: none;

      .icon {
        @include square-icon (32px, $c-gray-2);
      }
    }

    .content {
      width: 100%;

      .header {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .logo {
          @include font-additional (20px, 600, 100%, 0.05em, uppercase);
          color: $c-black;
        }

        .aboutShop {
          max-width: 172px;
          @include font-base (12px, 400, 110%, 0.05em, none);
          color: $c-black;
        }
      }

      .navigation {
        padding-top: 44px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;

        a {
          width: 100%;
          padding: 8px 12px;
          padding-left: 18px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          border: 1px dashed $c-black;
          border-radius: 16px;

          p {
            @include font-base (16px, 500, 100%, 0.1em, none);
            color: $c-black;
          }

          .icon {
            @include square-icon (16px, $c-black);
          }
        }
      }

      .buttons {
        padding-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;

        a {
          padding: 12px;

          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;

          border: 1px dashed $c-black;
          border-radius: 16px;

          .text {
            @include font-base (16px, 500, 100%, 0.1em, none);
            color: $c-black;
          }

          .icon {
            @include square-icon (32px, $c-black);
          }
        }
      }
    }

    .btnCallRequest {
      width: 100%;
      padding: 6px 12px;
      padding-left: 18px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      border: none;
      border-radius: 16px;
      background: $c-accent-2;

      .text {
        @include font-base (16px, 500, 100%, 0.1em, none);
        color: $c-accent-4;
      }

      .icon {
        @include square-icon (24px, $c-accent-4);
      }
    }
  }
}