@import 'src/resources/styles';

.tooltip {
  position: absolute;
  z-index: $zi-tooltip;
  display: inline;

  width: auto;
  max-width: 600px;
  padding: 6px 12px;

  @include font-base (14px, 500, 110%, 0.05em, none);
  color: $c-black;

  background: $c-gray-1;
  border-radius: 12px;
  box-shadow: 4px 4px 6px 0 rgba(#bbb, 0.1);

  span {
    font-weight: 600;
    color: $c-highlight-red;
  }

  @media screen and (max-width: $size-2-column-width) {
    font-size: 12px;
    max-width: 300px;
  }
}