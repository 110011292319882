@import 'src/resources/styles';

.wrap {
  width: 100%;
  margin: 96px 0;

  display: flex;
  flex-direction: column;
  gap: 64px;

  .loader {
    width: 100%;
    height: 60vh;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 120px;

    .infoWrap {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .props {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 24px;
      }

      .annotations {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }
  }

  @media screen and (max-width: $size-4-column-width) {
    
    .info {
      grid-template-columns: auto 1fr;

      .infoWrap {
        align-content: end;
      }
    }
  }

  @media screen and (max-width: $size-3-column-width) {
    gap: 44px;
    
    .info {
      gap: 32px; 
    }
  }

  @media screen and (max-width: $size-2-column-width) {
    gap: 32px;
    
    .info {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .imagesWrap {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}