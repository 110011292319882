@import 'src/resources/styles';

.loader {
  height: 60vh;
}

.wrap {
  width: 100%;
  padding: 80px 0;

  .gallery {
    padding-top: 64px;
    display: grid;
    gap: 22px;

    .column {
      display: flex;
      flex-direction: column;
      gap: 22px;

      .imgContainer {
        position: relative;
        @include hover;

        .image {
          width: 100%;
          border-radius: 16px;
        }

        .info {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 3px;
          overflow-x: hidden;
          overflow-y: hidden;

          padding: 16px;
          padding-top: 22px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;

          background: rgba($c-black, 0.5);
          border-radius: 16px;
          opacity: 0;
          @include hover;

          .attributes {
            display: flex;
            align-items: center;
            gap: 16px;
          }

          .prop {
            position: relative;
            height: 48px;
            width: 48px;

            display: flex;
            align-items: center;
            justify-content: center;

            background: rgba($c-gray-3, 0.7);
            border-radius: 50%;

            transform: translateX(200%);
            transition: all 0.5s ease-out;

            & > .icon {
              @include square-icon (32px, $c-black);
            }

            .mark {
              position: absolute;
              top: -6px;
              left: -12px;

              width: 24px;
              height: 24px;

              display: flex;
              align-items: center;
              justify-content: center;

              border-radius: 50%;

              .icon {
                @include square-icon (16px, $c-white);
              }

              p {
                @include font-base (14px, 500, 100%, 0, none);
                color: $c-white;
              }
            }

            &.propComment .mark {
              background: $c-highlight-green;
            }

            &.propProducts .mark {
              background: $c-highlight-red;
            }

            &.propGo {
              width: 56px;
              height: 56px;
            }
          }
        }

        &:hover .info {
          opacity: 1;

          .prop {
            transform: translateX(0);
          }
        }
      }
    }

    @media screen and (max-width: 1100px) {
      &, .column { gap: 18px; }
    }

    @media screen and (max-width: 940px) {
      &, .column { gap: 12px; }
    }

    @media screen and (max-width: 820px) {
      padding-top: 44px;
      &, .column { gap: 20px; }
      .column .imgContainer {
        .image, .info { border-radius: 8px; }
      }
    }

    @media screen and (max-width: 650px) {
      padding-top: 32px;
      &, .column { gap: 12px; }
    }

    @media screen and (max-width: 420px) {
      padding-top: 24px;
      &, .column { gap: 8px; }
    }
  }
}