@import 'src/resources/styles';

.wrap {
  width: 100%;

  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    padding: 6px 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    border: none;
    border-radius: 8px;
    background: $c-gray-0;

    @include hover;

    .textWrap {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .comment {
        @include font-base (14px, 500, 100%, 0, lowercase);
        text-align: left;
        color: $c-gray-4;
      }
      
      .groupName {
        @include font-base (18px, 600, 100%, 0.05em, uppercase);
        text-align: left;
        color: $c-black;
      }
    }

    .icon {
      @include square-icon (32px, $c-gray-3);
      transition: all 0.4s ease-in-out;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    &.isSale {

      .textWrap {
        .comment {
          color: $c-highlight-red;
        }
      }

      .icon {
        @include square-icon (32px, $c-highlight-red);
      }
    }

    @media screen and (max-width: $size-2-column-width) {
      .textWrap {
        .comment {
          font-size: 10px;
        }

        .groupName {
          font-size: 16px;
        }
      }
    }
  }

  .list {
    padding-top: 6px;
    padding-bottom: 32px;

    transform: scaleY(1);    
    transform-origin: top;
    transition: all 0.15s ease;

    .item .card {
      padding-top: 16px;
    }

    &.hidden {
      transform: scaleY(0);
      height: 0;
      padding-bottom: 6px;
      opacity: 0;
    }
  }
}