@import 'src/resources/styles';

.loadingWrap {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @include font-base (14px, 600, 130%, 0.05em, none);
  text-align: center;
  color: $c-accent-4;
}
