@import 'src/resources/styles';

.window {
  max-width: $size-container-width !important;
  gap: 44px !important;
  border-radius: 32px !important;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: column;

  .form {
    width: 100%;
    height: 80px;
    padding: 6px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    background: $c-white;
    border: 2px solid $c-gray-2;
    border-radius: 40px;

    z-index: 1;

    .input {
      flex-grow: 1;
      height: 100%;
      padding-left: 38px;

      background: inherit;
      border-radius: 34px;
      border: none;
      outline: none !important;

      @include font-base (20px, 500, 100%, 0.05em, none);
      color: $c-black;

      &::placeholder {
        color: $c-gray-3;
      }
    }

    .submit {
      height: 100%;
      padding: 0 32px;
      padding-top: 4px;
      padding-left: 36px;

      display: flex;
      align-items: center;
      gap: 16px;

      background: $c-accent-2;
      border: none;
      border-radius: 34px;

      @include hover;

      p {
        display: none;
        @include font-additional (20px, 600, 100%, 0.1em, uppercase);
        color: $c-white;
      }

      .icon {
        @include square-icon (44px, $c-accent-3);
      }

      &:disabled {
        padding-left: 24px;
        padding-right: 20px;
        background: inherit;

        .icon path {
          fill: $c-gray-2;
        }
      }
    }
  }

  .error {
    height: 20px;
    margin: 0;
    padding-left: 32px;
    display: flex;
    align-items: center;
    @include font-base (16px, 500, 110%, 0.05em, none);
    color: $c-highlight-red;
    
    transition: all 0.4s ease;
    transform: translateY(-30px);
    z-index: 0;

    &.active {
      margin: 6px 0;
      transform: translateY(0);
    }
  }

  .readyQueries {
    width: 100%;
    padding-left: 20px;
    padding-top: 12px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px 16px;

    .title {
      padding-right: 16px;
      @include font-base (20px, 700, 100%, 0.05em, uppercase);
      color: $c-gray-3;
    }

    .queryLabel {
      height: 36px;
      padding: 0 16px;

      display: flex;
      align-items: center;
      gap: 12px;

      background: $c-gray-1;
      border-radius: 20px;
      border: none;
      outline: none !important;

      @include hover;

      .text {
        @include font-base (20px, 600, 110%, 0.1em, uppercase);
        text-align: left;
        color: $c-gray-3;
        @include hover;
      }

      .btnRemove {
        background: inherit;
        border: none;
        outline: none !important;
        @include hover;

        .icon {
          margin-top: 4px;
          @include square-icon (28px, $c-gray-3);
        }
      }

      &:hover {
        background: $c-accent-2;
        .text { color: $c-accent-4; }
        .icon path { fill: $c-accent-4; }
      }
    }
  }
}

@media screen and (max-width: $size-3-column-width) {
  .window {
    gap: 32px !important;
  }

  .searchBar {
    .form {
      height: 64px;
      .submit .icon {
        @include square-icon (36px, $c-accent-3);
      }
    }

    .error {
      padding-left: 20px;
    }

    .readyQueries {
      gap: 6px 12px;

      .title {
        padding-right: 8px;
      }

      .title, .queryLabel .text {
        font-size: 16px;
      }

      .queryLabel {
        height: auto;
        padding-top: 4px;
        padding-bottom: 4px;

        .btnRemove .icon {
          @include square-icon-size (20px);
        }
      }
    }
  }
}

@media screen and (max-width: $size-2-column-width) {
  .window {
    gap: 24px !important;
    border-radius: 16px !important;
  }

  .searchBar {
    .form {
      border-radius: 16px;
      gap: 8px;

      .input {
        padding-left: 16px;
        border-radius: 12px;
      }

      .submit {
        border-radius: 12px;
        padding-left: 24px;
        padding-right: 20px;
      }
    }

    .error {
      height: 12px;
      padding-left: 6px;
      font-size: 12px;

      &.active {
        margin-top: 8px;
        margin-bottom: 0;
      }
    }

    .readyQueries {
      padding-left: 6px;

      .title {
        padding-bottom: 4px;
      }

      .queryLabel {
        border-radius: 12px;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .searchBar .form {
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 6px;

    border: none;
    border-radius: 0;

    .input {
      width: 100%;
      padding: 12px 0;
      padding-left: 16px;
      border: 2px solid $c-gray-2;
      border-radius: 12px;
      font-size: 16px;
    }

    .submit {
      width: 100%;
      padding: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: $c-highlight-red;
      border-radius: 12px;
      
      p {
        display: block;
      }

      .icon {
        @include square-icon (22px, $c-white);
      }

      &:disabled {
        padding: 10px;
        background: $c-gray-3;
        .icon { 
          @include square-icon (22px, $c-white); 
        }
      }
    }
  }

  .searchBar .error.active {
    justify-content: center;
    text-align: center;
    margin-bottom: 24px;
  }
}