@import 'src/resources/styles';

.sectionsRows {
  display: flex;
  flex-direction: column;
  gap: 64px;

  @media screen and (max-width: $size-3-column-width) {
    gap: 44px;
  }
}

.sectionsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 32px;
}

.gridViewButtons {
  display: flex;
  align-items: center;
  gap: 12px;

  .gridViewBtn {
    width: auto;
    height: auto;
    padding: 0;

    background: inherit;
    border: none;

    .icon {
      @include square-icon (24px, $c-gray-2);
      @include hover;
    }

    .icon.active {
      @include square-icon (24px, $c-black);
    }

    &:hover {
      .icon {
        @include square-icon (24px, $c-gray-3);
      }
  
      .icon.active {
        @include square-icon (24px, $c-black);
        cursor: default;
      }
    }
  }
}