@import 'src/resources/styles';

@mixin resizeChange ($gap, $fzPriceLength, $fzLabel) {
  gap: $gap;

  .header .lengthInfo .length,
  .header .priceInfo .priceWrap .price{
    font-size: $fzPriceLength;
  }
  
  .header .lengthInfo,
  .header .priceInfo {
    .label {
      font-size: $fzLabel;
    }
  }
}


.wrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 32px;

  .header {
    width: 100%;
    padding: 16px 0;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 28px; 

    border-bottom: 1px solid $c-accent-2;

    .lengthInfo {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .label {
        @include font-base (14px, 500, 90%, 0.05em, uppercase);
        color: $c-gray-3;
      }

      .length {
        @include font-base (50px, 700, 90%, 0, none);
        color: $c-black;

        span {
          font-size: 20px;
          text-transform: uppercase;
        }
      }
    }

    .priceInfo {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .label {
        @include font-base (14px, 500, 90%, 0.05em, uppercase);
        color: $c-gray-3;
      }

      .priceWrap {
        display: flex;
        align-items: center;
        gap: 12px;

        .price {
          @include font-base (50px, 700, 90%, 0, none);
          color: $c-black;

          .active {
            color: $c-highlight-red;
          }
        }

        .priceMeasureUnit {
          padding-top: 3px;
          display: flex;
          flex-direction: column;
          gap: 3px;

          span {
            @include font-base (16px, 700, 90%, 0, uppercase);
            color: $c-black;

            &:first-child {
              padding-bottom: 3px;
              border-bottom: 2px solid $c-black;
            }
          }
        }

        .priceInfoIcon {
          margin-top: 3px;
          margin-left: 12px;

          @include square-icon (24px, $c-highlight-red);
          @include hover;
        }
      }
    }
  }

  .sizeUnits {
    width: 100%;
    
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px 28px;
  }

  @media screen and (max-width: $size-4-column-width) {
    @include resizeChange(28px, 44px, 14px);
  }

  @media screen and (max-width: $size-3-column-width) {
    @include resizeChange(24px, 36px, 14px);
  }

  @media screen and (max-width: $size-2-column-width) {
    @include resizeChange(24px, 24px, 14px);

    .header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0;

      .lengthInfo {
        gap: 12px;
      }
  
      .priceInfo .priceWrap .priceInfoIcon {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 470px) {
    @include resizeChange(24px, 20px, 12px);

    .header {

      .lengthInfo {
        gap: 12px;
      }

      .lengthInfo .length span {
        font-size: 12px;
      }

      .label {
        letter-spacing: 0 !important;
      }

      .priceInfo .priceWrap {
        gap: 8px;
      }

      .priceInfo .priceWrap .priceMeasureUnit {
        gap: 2px;

        span {
          font-size: 10px;

          &:first-child {
            padding-bottom: 2px;
            border-bottom-width: 1px;
          }
        }
      }
    }

    .sizeUnits {
      row-gap: 12px;
    }
  }
}
