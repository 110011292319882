@import 'src/resources/styles';

.wrap {
  position: relative;
  width: 100%;
  padding: 18px 16px;
  padding-bottom: 12px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  border: 2px dashed $c-accent-2;
  border-radius: 12px;

  transition: all 0.4s ease;

  .arrow {
    position: absolute;
    right: 0;
    top: 0;

    @include square-icon (64px, $c-accent-2);
    transform: rotate(-45deg);
    transition: all 0.4s ease;
  }

  .title {
    @include font-additional (32px, 700, 100%, 0.1em, uppercase);
    color: $c-accent-3;
    transition: all 0.4s ease;
  }

  .description {
    width: 80%;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 12px;

    .icon {
      @include square-icon (32px, $c-accent-3);
    }

    .text {
      @include font-base (16px, 500, 100%, 0.1em, none);
      color: $c-accent-3;
      transition: all 0.4s ease;
    }
  }

  .button {
    background: inherit;
  }

  &:hover {
    background: $c-accent-1;
    border-color: $c-accent-3;

    .arrow {
      transform: rotate(0);
      top: 6px;
      right: 6px;

      path {
        fill: $c-accent-3;
      }
    }

    .title,
    .description .text {
      color: $c-accent-4;
    }

    .button {
      border-color: $c-accent-4;
      background: $c-accent-4;

      p {
        color: $c-white!important;
      }

      svg path {
        fill: $c-white !important;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .arrow {
      display: none;
    }

    .title {
      font-size: 24px;
    }

    .description {
      width: 100%;

      .text {
        font-size: 14px;
      }
    }
  }
}