@import 'src/resources/styles';

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .list {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 16px 24px;

    .label {
      width: fit-content;
      max-width: 280px;
      padding: 8px 24px 12px 16px;
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 14px;
      
      border-radius: 16px;

      .icon {
        @include square-icon-size (44px);
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 3px;

        .type {
          @include font-base (12px, 500, 100%, 0.1em, none);
        }

        .name {
          @include font-base (16px, 700, 110%, 0.1em, uppercase);
        }
      }
    }

    .pageLabel {
      background: $c-gray-0;

      .content {
        padding-left: 6px;
        .type { color: $c-gray-4; }
        .name { color: $c-black; }
      }

      .icon path { fill: $c-gray-2; }
    }

    .groupLabel {
      background: $c-accent-1;

      .content {
        padding-left: 2px;
        .type { color: $c-accent-4; }
        .name { color: $c-black; }
      }

      .icon path { fill: $c-accent-3; }
    }
  }
}