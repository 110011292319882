@import 'src/resources/styles';

.text {
  max-width: 500px;
  width: 100%;

  @include font-base (18px, 500, 130%, 0.05em, none);
  text-align: justify;
  color: $c-black;

  @media screen and (max-width: $size-3-column-width) {
    font-size: 16px;
  }

  @media screen and (max-width: $size-2-column-width) {
    font-size: 14px;
  }
}