@import 'src/resources/styles';

.wrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .barArea {
    width: 100%;
    height: 12px;

    background: $c-gray-1;
    border-radius: 6px;

    .barLine {
      height: 12px;
      background: $c-highlight-red;
      border-radius: 6px;
    }

    @media screen and (max-width: $size-2-column-width) {
      &, .barLine {
        height: 8px;
        border-radius: 4px;
      }
    }
  }

  .info {
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .description {
      display: flex;
      align-items: center;
      gap: 12px;

      .icon {
        @include square-icon (24px, $c-gray-2);
      }

      .text {
        @include font-base (14px, 500, 100%, 0, none);
        color: $c-black;

        @media screen and (max-width: $size-2-column-width) {
          text-align: justify;
        }

        span {
          @include font-base (14px, 700, 100%, 0, uppercase);
          color: $c-highlight-red;
        }
      }
    }

    .barStatus {
      @include font-base (16px, 700, 100%, 0, uppercase);
      color: $c-highlight-red;
      text-align: right;
      white-space: nowrap;

      .descForSmallScreen {
        padding-right: 6px;

        @include font-base (16px, 500, 100%, 0, none);
        color: $c-black;
      }
    }
  }

  @media screen and (max-width: 800px) {
    gap: 8px;
    
    .info {
      justify-content: flex-end;
      gap: 12px;

      .barStatus,
      .barStatus .descForSmallScreen {
        font-size: 14px;
      }
    }
  }
}
