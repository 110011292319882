@import 'src/resources/styles';

.wrap {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  height: 100%;
  padding: 180px $size-min-screen-border-gap;
  padding-bottom: 128px;
  position: relative;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 52px;

  background: $c-accent-1;

  .aboutShop {
    width: 100%;
    max-width: 855px;

    @include font-additional (64px, 400, 100%, 0, none);
    text-align: center;
    color: $c-black;
  }

  .search {
    width: 100%;
    max-width: 840px;

    display: flex;
    align-items: stretch;
    gap: 16px;

    background: rgba($c-white, 0);
    border: none;
    outline: none !important;
    text-align: left;
    z-index: 1;

    .pseudoInput {
      flex-grow: 1;

      padding: 18px 0;
      padding-left: 32px;

      background: $c-white;
      border: 1px solid $c-highlight-green;
      border-radius: 12px;
      outline: none !important;

      @include font-base (20px, 400, 100%, 0.1em, none);
      color: $c-highlight-green;

      @include hover;
    }

    .pseudoBtn {
      padding: 0 11px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: $c-highlight-green;
      border: none;
      border-radius: 12px;

      @include hover;

      .icon {
        @include square-icon (36px, $c-white);
      }
    }
  }

  .advantages {
    display: flex;
    align-items: center;
    gap: 24px;

    @include font-base (20px, 400, 100%, 0.1em, uppercase);
    color: $c-highlight-green;
  }

  .img {
    position: absolute;

    &.imgL {
      width: 16vw;
      max-width: 240px;
      top: min(20vh, 150px);
      left: -60px;
    }

    &.imgRT {
      width: 13vw;
      max-width: 200px;
      top: min(13vh, 125px);
      right: -50px;
    }

    &.imgRB {
      width: 22vw;
      max-width: 320px;
      bottom: min(5vh, 40px);
      right: -60px;
    }
  }

  @media screen and (max-width: 1200px) {
    padding-top: 200px;

    .aboutShop {
      max-width: 700px;
      font-size: 52px;
    }

    .searchWrap {
      max-width: 700px;
    }
  }

  @media screen and (max-width: 1000px) {
    min-height: 0;

    .aboutShop {
      max-width: 600px;
      font-size: 45px;
    }

    .searchWrap {
      max-width: 600px;
    }
  }

  @media screen and (max-width: 900px) {
    padding-top: 140px;
    min-height: 0;
    gap: 36px;

    .imagesContainer {
      display: none;
    }
  }

  @media screen and (max-width: 720px) {
    .aboutShop {
      max-width: 600px;
      font-size: 32px;
    }

    .searchWrap {
      max-width: 600px;
      .input {
        padding-top: 12px;
        padding-bottom: 12px;
      }
      .searchBtn .icon {
        @include square-icon (28px, $c-white);
      }
    }

    .advantages {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 470px) {
    gap: 24px;

    .aboutShop {
      font-size: 28px;
    }

    .searchWrap {
      gap: 12px;
      .input {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        font-size: 16px;

        &::placeholder {
          font-size: 16px;
        }
      }
      .searchBtn .icon {
        @include square-icon (20px, $c-white);
      }
    }

    .advantages {
      gap: 8px;
      font-size: 12px;
    }
  }
}