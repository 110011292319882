@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 44px;

  .text {
    width: 100%;
    max-width: 270px;
    padding: 2px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      @include font-additional (20px, 500, 100%, 0, none);
      color: $c-black;
    }

    .subtitle {
      @include font-base (14px, 500, 100%, 0, none);
      color: $c-gray-3;
    }
  }

  .link {
    width: 100%;
    max-width: 250px;
    padding: 12px;
    position: relative;
    flex-grow: 1;

    background: $c-white;
    border: 2px dashed $c-accent-4;
    border-radius: 12px;

    @include hover;

    .label {
      max-width: 100px;
      @include font-additional (18px, 500, 100%, 0, none);
      color: $c-accent-4;
    }

    .icon {
      position: absolute;
      right: 2px;
      bottom: 2px;
      @include square-icon (32px, $c-accent-4);
      transform: rotate(45deg);
    }

    &:hover {
      background: $c-accent-1;

      .icon {
        transform: rotate(0);
      }
    }
  }

  @media screen and (max-width: 1220px) {
    gap: 32px;
  }

  @media screen and (max-width: $size-3-column-width) {
    gap: 44px;
    padding: 16px;

    .text {
      max-width: 500px;
    }

    .link {
      max-width: 100%;
    }
  }

  @media screen and (max-width: $size-2-column-width) {
    gap: 32px;
    padding: 16px 8px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 24px;

    .text {
      padding: 0 8px;
    }
  }
}