@import 'src/resources/styles';

@mixin labelColors ($bg, $content) {
  background: $bg;
  .icon path { fill: $content; }
  .text { color: $content; }
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;

  .label {
    width: auto;
    padding: 6px 12px;
    padding-right: 20px;

    display: flex;
    align-items: center;
    gap: 12px;

    border-radius: 8px;

    .icon {
      @include square-icon-size (18px);
    }

    .text {
      @include font-base (14px, 600, 120%, 0.1em, uppercase);
    }

    &.date {
      @include labelColors($c-gray-0, $c-gray-4);
    }

    &.madeByCustomer {
      @include labelColors($c-accent-2, $c-accent-4);
    }

    &.madeByUs {
      @include labelColors(rgba($c-highlight-green, 0.3), $c-highlight-green);
    }

    &.sample {
      @include labelColors(rgba($c-highlight-red, 0.3), $c-highlight-red);
    }
  }
}