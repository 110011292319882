@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding-bottom: 44px;
  display: flex;
  align-items: center;
  gap: 52px;

  .icons {
    display: flex;
    align-items: center;
    gap: 16px;

    svg {
      @include square-icon (90px, $c-gray-3);
    }
  }

  .text {
    max-width: 470px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    p {
      @include font-base (16px, 500, 110%, 0.1em, none);
      color: $c-black;
    }
  }

  @media screen and (max-width: 920px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    .icons svg {
      @include square-icon (64px, $c-gray-3);
    }
  }

  @media screen and (max-width: 710px) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 24px;
    padding-bottom: 32px;

    .icons {
      flex-direction: column;
      gap: 6px;

      svg {
        @include square-icon (32px, $c-gray-3);
      }
    }
  }

  @media screen and (max-width: 430px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .icons {
      flex-direction: row;
      gap: 12px;
    }
  }
}