@import 'src/resources/styles';

.wrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 6px;

  .buttonWrap {
    padding-bottom: 14px;
    background: $c-white;

    @media screen and (max-width: $size-3-column-width) {
      position: fixed;
      bottom: 0;
      left: $size-min-screen-border-gap;
      right: $size-min-screen-border-gap;
      z-index: $zi-fixed-elements;

      padding-top: 40px;
      background: linear-gradient(0deg, #FFF 0%, #FFF 70%, rgba(255, 255, 255, 0) 100%);
    }

    .makeOrder {
      width: 100%;
      padding: 14px 12px;

      border: none;
      border-radius: 12px;
      background: $c-highlight-red;

      @include font-additional (16px, 500, 120%, 0.2em, uppercase);
      color: $c-white;

      @include hover;

      &:hover {
        background: $c-highlight-red-hover;
      }
    }
  }

  .orderInfo {
    width: 100%;
    padding: 12px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    border-radius: 12px;
    background: $c-gray-1;

    .commonAmountPrice {
      display: flex;
      flex-wrap: nowrap;
      gap: 6px;

      @include font-base(14px, 500, 120%, 0.05em, none);
      color: $c-black;
      white-space: nowrap;

      .dots {
        flex-grow: 1;
        height: 14px;
        border-bottom: 1px dashed $c-gray-2;
      }
    }

    .orderComposition {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .discountsButton {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border: none;
        background: inherit;

        @include font-base (14px, 500, 120%, 0.05em, none);
        color: $c-black;

        @include hover;

        .icon {
          @include square-icon (20px, $c-black);
          transition: all 0.3s ease;
        }

        &.isShow {
          .icon {
            transform: rotate(90deg);
          }
        }
      }

      .groupsDiscountsList {
        padding-left: 12px;

        display: flex;
        flex-direction: column;
        gap: 4px;

        transform: scaleY(0);  
        transform-origin: top;
        transition: all 0.15s ease;

        height: 0;
        opacity: 0;

        &.isShow {
          transform: scaleY(1);
          height: auto;
          opacity: 1;
        }

        .withoutDiscounts {
          @include font-base (14px, 500, 100%, 0.05em, none);
          color: $c-gray-3;
        }

        .item {
          display: flex;
          align-items: flex-end;
          gap: 6px;

          @include font-base (12px, 500, 100%, 0.05em, uppercase);

          .name {
            color: $c-gray-4;
          }

          .dots {
            flex-grow: 1;
            height: 11px;
            min-width: 24px;
            border-bottom: 1px dashed $c-gray-2;
          }

          .discount {
            color: $c-highlight-red;
            white-space: nowrap;
          }

          &:last-child {
            padding-bottom: 16px;
          }
        }
      }
    }

    .total {
      width: calc(100% - 10px);
      margin-left: 10px;
      padding-top: 12px;

      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;

      border-top: 2px solid $c-gray-2;

      .text {
        @include font-base (20px, 600, 120%, 0.05em, uppercase);
        color: $c-black;
      }

      .price {
        @include font-base (20px, 700, 120%, 0.05em, uppercase);
        color: $c-highlight-red;

        span {
          padding-left: 2px;
          font-size: 16px;
        }
      }
    }

    @media screen and (max-width: $size-3-column-width) {
      margin-bottom: 50px;
    }
  }
}