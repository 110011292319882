@import 'src/resources/styles';

.loaderWrap {
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap {
  width: 100%;
  padding: 80px 0;

  .description {
    @include font-base (20px, 500, 110%, 0.1em, none);
    text-align: justify;
    color: $c-black;
  }

  .content {
    padding-top: 32px;

    display: flex;
    justify-content: space-between;

    .leftColumn {
      max-width: 555px;
      padding-left: 70px;

      display: flex;
      flex-direction: column;
      gap: 44px;
    }

    .rightColumn {
      max-width: 580px;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 52px;
    }
  }

  @media screen and (max-width: $size-4-column-width) {
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 64px;

      .leftColumn {
        max-width: 480px;
        padding-left: 0;
      }

      .rightColumn {
        max-width: none;
      }
    }
  }

  @media screen and (max-width: 1110px) {
    .description {
      text-align: left;
    }

    .content {
      gap: 32px;
    }
  }

  @media screen and (max-width: 800px) {
    .content {
      padding-top: 44px;
      display: flex;
      flex-direction: column-reverse;
      gap: 44px;

      .leftColumn,
      .rightColumn  {
        gap: 44px;
        max-width: none;
      }
    }
  }
}