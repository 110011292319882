@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding: 32px 0;

  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: $size-2-column-width) {
    padding: 20px 0;
    gap: 20px;
  }

  .header {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
}