@import 'src/resources/styles';

@mixin resizeChange ($col4, $col3, $col2, $col1) {
  @media screen and (max-width: $size-4-column-width) {
    font-size: $col4;
  }

  @media screen and (max-width: $size-3-column-width) {
    font-size: $col3;
  }

  @media screen and (max-width: $size-2-column-width) {
    font-size: $col2;
  }

  @media screen and (max-width: $size-1-column-width) {
    font-size: $col1;
  }
}


.title {
  display: block;
  width: 100%;

  @include font-base (16px, 700, 100%, 0.05em, uppercase);
  color: $c-black;

  overflow-y: hidden;
}

.type_card {
  font-size: 14px;
}

.type_section {
  font-size: 16px;
  letter-spacing: 0.1em;

  @include resizeChange(16px, 16px, 14px, 14px);
}

.type_base_page {
  font-size: 96px;

  @include resizeChange(80px, 64px, 36px, 28px);
}

.type_product_page {
  font-size: 80px;
  
  @include resizeChange(64px, 40px, 28px, 20px);
}

.type_modal {
  font-size: 64px;

  @include resizeChange(48px, 32px, 28px, 20px);
}

.type_main_page_deco {
  @include font-additional (64px, 400, 120%, 0, none);
  text-align: center;

  @include resizeChange(48px, 32px, 28px, 20px);
}