@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding: 12px;
  padding-right: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  background: $c-gray-0;
  border-radius: 8px;

  .label {
    display: flex;
    align-items: center;
    gap: 12px;

    .icon {
      @include square-icon (18px, $c-gray-4);
    }

    .text {
      padding-top: 2px;
      @include font-base (14px, 600, 100%, 0.1em, uppercase);
      color: $c-gray-4;
    }
  }

  .content {
    width: 100%;
    padding-left: 30px;

    display: flex;
    flex-direction: column;
    gap: 4px;

    .author {
      @include font-base (12px, 700, 120%, 0.05em, none);
      color: $c-highlight-red;
    }

    .comment {
      @include font-base (12px, 500, 120%, 0.05em, none);
      color: $c-black;
    }
  }
}