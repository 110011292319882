@import 'src/resources/styles';

@keyframes iconRotation {
	0% {
		animation-timing-function: ease-out;
		transform: scale(1);
		transform-origin: center center;
	}

	10% {
		animation-timing-function: ease-in;
		transform: scale(0.91);
	}

	17% {
		animation-timing-function: ease-out;
		transform: scale(0.98);
	}

	33% {
		animation-timing-function: ease-in;
		transform: scale(0.87);
	}

	45% {
		animation-timing-function: ease-out;
		transform: scale(1);
	}
}

.wrap {
  width: 100%;
  padding: 20px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .observer {
    width: 220px;
    height: 32px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  
    border: none;
    border-radius: 16px;
    background-color: $c-accent-2;

    @include hover;
  
    .text {
      @include font-base (14px, 600, 100%, 0.05em, none);
      color: $c-accent-4;
    }
  
    .icon {
      @include square-icon (18px, $c-accent-3);
    }

    &:hover {
      width: 230px;
    }
  }

  .observer.loading {
    width: 50px;
    height: 50px;

    border-radius: 12px;

    .icon {
      @include square-icon (32px, $c-accent-3);
      animation: iconRotation 1.5s ease 0s infinite normal forwards;
    }
  }
}