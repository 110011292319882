@import 'src/resources/styles';

.wrap {
  width: 100%;
  min-height: 100vh;
  padding: 128px 0;
  position: relative;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: auto;
    margin: 0 $size-min-screen-border-gap;
    max-width: $size-container-width;
    position: relative;
  }

  .titleWrap {
    z-index: 1;
  }

  .list {
    display: flex;
    scroll-behavior: smooth;
    
    .item {
      margin-right: 44px;
      margin-top: 30px;
      z-index: 1;
      @include hover;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        margin-top: 0;
      }
    }

    .offer {
      width: 340px;
      height: 500px;
      padding: 16px 24px;

      display: grid;
      grid-template-rows: 5fr 4fr 1fr;

      border: none;
      border-radius: 16px;
      background: $c-gray-0;
      @include hover;

      .icon {
        @include square-icon (100px, rgba($c-gray-2, 0.7));
      }

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        .title {
          @include font-additional (34px, 600, 110%, 0.05em , uppercase);
          text-align: left;
          color: $c-highlight-red;
        }

        .description {
          @include font-base (14px, 500, 110%, 0.05em, none);
          text-align: left;
          color: $c-black;
        }
      }

      .marker {
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .text {
          @include font-base (16px, 600, 110%, 0.05em, uppercase);
          color: $c-black;
        }

        .icon {
          @include square-icon (24px, $c-black);
        }
      }
    }
  }

  .decoContainer {
    svg {
      position: absolute;
      width: 130px;
      height: 130px;
      
      path {
        fill: $c-accent-1;
      }
    }

    .iconL {
      top: 50%;
      left: -25px;
      transform: rotate(-15deg);
    }

    .iconC {
      top: 78%;
      left: 54%;
      transform: rotate(-15deg);
    }

    .iconR {
      top: 25%;
      right: -30px;
      transform: rotate(30deg);
    }
  }

  @media screen and (max-width: 1140px) {
    min-height: 0;
    padding: 100px 0;

    .container {
      width: 100%;

      .list .item:first-child {
        margin-left: 64px;
      }

      .list .item:last-child {
        margin-right: 64px;
      }
    }
  }
}