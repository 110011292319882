@import 'src/resources/styles';

.wrap {
  max-width: 600px;
  min-width: 300px;
  width: 100%;
  height: 60px;

  padding: 8px;
  padding-left: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  background: $c-accent-1;
  border-radius: 12px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .length {
      @include font-base (28px, 700, 90%, 0, none);
      color: $c-black;
      white-space: nowrap;
    }

    .price {
      @include font-base (16px, 600, 90%, 0, none);
      color: $c-accent-4;
      white-space: nowrap;
    }
  }

  .addButton {
    width: 44px;
    height: 44px;

    background: $c-white;
    border: none;
    border-radius: 8px;
    
    @include hover;

    .icon {
      margin-top: 3px;
      @include square-icon (24px, $c-black);
    }
  }

  .controlArea {
    width: 100%;
    height: 44px;
    padding: 0 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    background: $c-white;
    border-radius: 8px;

    .button {
      background: inherit;
      border: none;

      @include hover;

      .icon {
        padding-top: 2px;
        @include square-icon (26px, $c-black);
      }
    }

    .controlInfo {
      display: flex;
      flex-direction: column;
      align-items: center;

      .amount {
        @include font-base (18px, 700, 120%, 0, none);
        color: $c-black;
      }

      .mainPrice {
        @include font-base (12px, 500, 120%, 0, none);
        color: $c-accent-4;
      }
    }
  }
}