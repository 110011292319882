@import 'src/resources/styles';

.wrap {
  max-width: 400px;
  min-width: 300px;
  width: 100%;
  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 28px;

  background: $c-accent-1;
  border-radius: 12px;

  .info {
    flex-grow: 1;

    .props {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }
  
    .annotations {
      padding-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .pricesInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 6px;

    .label {
      @include font-base (12px, 500, 90%, 0.05em, uppercase);
      color: $c-gray-3;
    }

    .info {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;

      .prices {
        @include font-base (24px, 700, 90%, 0, uppercase);
        color: $c-black;

        .active {
          color: $c-highlight-red;
        }
      }

      .pricesUnit {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;

        span {
          padding: 0 1px;

          @include font-base (12px, 700, 90%, 0, uppercase);
          color: $c-black;
        
          &:first-child {
            padding-bottom: 3px;
            border-bottom: 1px solid $c-black;
          }
        }
      }

      .infoIcon {
        margin-left: 8px;
        @include square-icon (24px, $c-highlight-red);
        @include hover;
      }
    }
  }

  .addButton {
    width: 100%;
    height: 44px;

    background: $c-white;
    border: none;
    border-radius: 12px;

    @include hover;

    .icon {
      padding-top: 2px;
      @include square-icon (26px, $c-black);
    }
  }

  .controlArea {
    width: 100%;
    height: 44px;
    padding: 0 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    background: $c-white;
    border-radius: 12px;

    .button {
      background: inherit;
      border: none;

      @include hover;

      .icon {
        padding-top: 2px;
        @include square-icon (26px, $c-black);
      }
    }

    .controlInfo {
      display: flex;
      flex-direction: column;
      align-items: center;

      .amount {
        @include font-base (18px, 700, 120%, 0, none);
        color: $c-black;
      }

      .mainPrice {
        @include font-base (12px, 500, 120%, 0, none);
        color: $c-accent-4;
      }
    }
  }
}