@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  background: $c-accent-1;
  border-radius: 32px;

  .header {
    display: flex;
    align-items: center;
    gap: 44px;

    padding-bottom: 16px;
    border-bottom: 1px solid $c-accent-2;

    .icon {
      @include square-icon (80px, $c-accent-3);
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .title {
        @include font-base (32px, 700, 100%, 0.05em, uppercase);
        color: $c-accent-4;
      }

      .description {
        max-width: 377px;
        width: 100%;
        @include font-base (14px, 600, 110%, 0.05em, none);
        color: $c-accent-3;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 24px;

      .field {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 8px;

        .label {
          padding-left: 6px;
          @include font-base (14px, 700, 110%, 0.05em, uppercase);
          color: $c-accent-4;
        }

        .input {
          width: 100%;
          resize: none;
          padding: 12px 24px;
          background: $c-white;
          border: 1px solid $c-accent-3;
          border-radius: 16px;
          outline: none !important;
          cursor: pointer;

          @include font-base (14px, 500, 110%, 0.05em, none);
          color: $c-black;

          &:disabled {
            background: $c-accent-2;
            border-color: $c-accent-2;
            cursor: default;
          }

          &::placeholder {
            font-size: 0;
            color: $c-white;
          }

          &:disabled::placeholder {
            @include font-additional (14px, 500, 100%, 0.05em, none);
            color: $c-accent-4;
          }
        }

        .error {
          height: 12px;
          padding-left: 6px;
          @include font-base (12px, 500, 100%, 0.05em, none);
          color: $c-highlight-red;
        }
      }

      &:last-child {
        padding-top: 8px;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        column-gap: 12px;
        row-gap: 6px;
      }
    }
  }

  .button, .buttonCancel {
    min-width: 0 !important;
  }

  .button {
    &:hover {
      background: $c-accent-4;
    }
  }

  .buttonCancel {
    &:hover {
      background: $c-white;
      border-color: $c-accent-4;
      p {
        color: $c-accent-4;
      }
      svg path {
        fill: $c-accent-4;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      position: relative;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 24px;

      .text {
        .title {
          padding-left: 48px;
        }
        .description {
          max-width: none;
        }
      }

      .icon {
        position: absolute;
        left: -2px;
        top: 0;
        @include square-icon (32px, $c-accent-3);
      }
    }
  }

  @media screen and (max-width: 410px) {
    .header {
      .icon {
        left: -2px;
        top: 0;
        @include square-icon (24px, $c-accent-3);
      }

      .text {
        .title {
          padding-left: 34px;
          font-size: 24px;
        }
        .description {
          font-size: 12px;
        }
      }
    }
  }
}