@import 'src/resources/styles';

@mixin useNoBracketsSmallView {
  .bracketLeft, .bracketRight {
    display: none;
  }

  .content {
    width: 100%;

    .card {
      width: 100%;
      max-width: none;
      justify-content: flex-start;
      gap: 12px;

      .icon {
        @include square-icon (36px, $c-gray-2);
      }
  
      .text {
        .comment { 
          font-size: 14px;
          text-align: left; 
        }

        .info { 
          font-size: 24px;
          text-align: left;
        }
      }
    }
  }
}

.wrap {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  .bracketLeft, .bracketRight {
    width: 16px;
    height: 170px;
    border: 3px dotted $c-gray-2;
  }

  .bracketLeft {
    border-right: none;
  }

  .bracketRight {
    border-left: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .card {
      width: 100%;
      max-width: 450px;
      padding: 8px 12px;
      padding-right: 20px;

      display: flex;
      align-items: center;
      gap: 16px;

      background: $c-gray-0;
      border-radius: 12px;

      @include hover;

      .icon {
        @include square-icon (50px, $c-gray-2);
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;

        .comment {
          @include font-additional (16px, 400, 100%, 0, none);
          text-align: right;
          color: $c-gray-4;
          @include hover;
        }

        .info {
          @include font-base (32px, 700, 100%, 0.07em, none);
          text-align: right;
          color: $c-black;
          @include hover;
        }
      }

      &:hover {
        background: $c-gray-1;

        .icon path {
          fill: $c-highlight-red;
        }

        .comment {
          color: $c-highlight-red;
        }
      }
    }
  }

  @media screen and (max-width: 1110px) and (min-width: 800px) {
    @include useNoBracketsSmallView;
  }

  @media screen and (max-width: 570px) {
    @include useNoBracketsSmallView;
  }

  @media screen and (max-width: 370px) {
    .content .card {
      .icon {
        @include square-icon (26px, $c-gray-2);
      }

      .text {
        .comment { font-size: 12px; }
        .info { font-size: 21px; }
      }
    }
  }
}