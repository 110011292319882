@import 'src/resources/styles';

.wrap {
  width: 100%;
  padding: 128px $size-min-screen-border-gap;
  padding-top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    width: 100%;
    max-width: $size-container-width;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
  }

  .content {
    width: 100%;
    padding-right: 64px;

    display: flex;
    justify-content: space-between;
    gap: 64px;

    .list {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .item {
        display: flex;
        gap: 32px;
        transition: all 0.3s linear;

        .icon {
          @include square-icon (36px, $c-black);
        }

        &:hover {
          transform: translateX(12px);
        }

        .text {
          width: 100%;
          max-width: 550px;
          padding-bottom: 24px;

          display: flex;
          flex-direction: column;
          gap: 12px;

          border-bottom: 1px solid $c-gray-2;

          .question {
            @include font-additional (24px, 600, 100%, 0, none);
            color: $c-black;
          }

          .answer {
            @include font-base (16px, 500, 110%, 0, none);
            color: $c-black;
          }
        }

        &:last-child .text {
          border-bottom: none;
        }
      }
    }

    .actions {
      width: 100%;
      max-width: 400px;

      display: flex;
      flex-direction: column;
      gap: 44px;

      .callRequestAction {
        width: 100%;
        padding: 24px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 24px;

        border-radius: 16px;
        border: 2px dashed $c-accent-2;
        transition: all 0.4s ease;

        &:hover {
          border-color: $c-accent-3;
        }

        .title {
          @include font-additional (28px, 500, 100%, 0, none);
          color: $c-accent-3;
        }

        .button:hover {
          background: $c-accent-4;
          * { color: $c-white !important; }
          svg path { fill: $c-white !important; }
        }
      }

      .goContactsAction {
        width: 100%;
        padding: 6px;

        display: flex;
        flex-direction: column;
        gap: 24px;

        .title {
          @include font-additional (28px, 500, 100%, 0, none);
          color: $c-black;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .content {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 900px) {
    .content {
      flex-direction: column;
      align-items: center;
      gap: 32px;

      .list {
        .item {
          .text {
            max-width: 100%;
            margin-right: 64px;
          }
        }
      }

      .actions {
        max-width: 100%;
        flex-direction: row;
        align-items: stretch;
        gap: 32px;

        .callRequestAction .title,
        .goContactsAction .title {
          font-size: 24px;
        }

        .goContactsAction {
          padding: 25px 0;
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    .content {
      .list {
        max-width: 620px;

        .item {
          gap: 16px;

          .icon {
            @include square-icon (26px, $c-black);
          }

          .text {
            margin-right: 12px;
          }
        }
      }

      .actions {
        max-width: 550px;
        flex-direction: column;
        gap: 24px;
      }
    }
  }

  @media screen and (max-width: $size-2-column-width) {
    .container {
      gap: 36px;
    }
  }
}