@import 'src/resources/styles';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .pastPrice {
    display: none;
    position: relative;
    @include font-base (12px, 500, 100%, 0.05em, uppercase);
    color: #888;
    text-decoration: none;

    &::before {
      content: "";
			border-bottom: 2px solid rgba($c-highlight-red, 0.9);
			position: absolute;
			top: 0;
			left: -5%;    
			width: 110%;
			height: 40%;
			transform: rotate(-4deg);
    }

    .ruble {
      padding-left: 2px;
      font-size: 10px;
    }
  }

  .currentPrice {
    @include font-base (18px, 700, 100%, -0.05em, uppercase);
    color: $c-black;

    .ruble {
      padding-left: 3px;
      font-size: 14px;
    }
  }

  .comment {
    @include font-base (12px, 500, 100%, 0.05em, none);
    color: #888;
  }

  &.isSale {
    .pastPrice {
      display: block;
    }

    .currentPrice {
      color: $c-highlight-red;
    }

    .comment {
      display: none;
    }
  }

  @media screen and (max-width: calc($size-1-column-width * 1.5)) {
    align-items: flex-start;
  }
}