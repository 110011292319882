@import 'src/resources/styles';

.button {
  min-width: 300px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  border: 1px solid $c-accent-4;
  background: $c-white;

  transition: all 0.4s ease;
  cursor: pointer;

  .text {
    @include font-base (16px, 500, 90%, 0.1em, none);
    color: $c-accent-4;
  }

  svg {
    @include square-icon (18px, $c-accent-4);
  }

  &:hover {
    gap: 10px;
  }

  @media screen and (max-width: 440px) {
    min-width: 200px;
    gap: 8px;

    .text {
      font-size: 14px;
      letter-spacing: 0;
    }
  }
}


// color

@mixin colorScheme ($bg, $border, $color, $hover-bg, $hover-border, $hover-color) {
  background: $bg;
  border-color: $border;

  .text {
    color: $color;
  }

  svg path {
    fill: $color;
  }

  &:hover {
    background: $hover-bg;
    border-color: $hover-border;

    .text {
      color: $hover-color;
    }

    svg path {
      fill: $hover-color;
    }
  }
}

.color__base {
  @include colorScheme($c-white, $c-accent-4, $c-accent-4, $c-white, $c-highlight-red, $c-highlight-red);
}

.color__white {
  @include colorScheme($c-white, $c-white, $c-black, $c-accent-1, $c-accent-1, $c-accent-4);
}

.color__accent {
  @include colorScheme($c-accent-4, $c-accent-4, $c-white, $c-highlight-red, $c-highlight-red, $c-white);
}

.color__highlight {
  @include colorScheme($c-highlight-red, $c-highlight-red, $c-white, $c-black, $c-black, $c-white);
}

.color__black {
  @include colorScheme($c-white, $c-black, $c-black, $c-black, $c-black, $c-white);
}

.color__light-accent {
  @include colorScheme($c-accent-2, $c-accent-2, $c-accent-4, $c-accent-4, $c-accent-4, $c-white);
}

// size

@mixin sizeScheme ($height) {
  min-height: $height;
  border-radius: calc($height / 2);
}

.size__base {
  @include sizeScheme(32px);
}

.size__tall {
  @include sizeScheme(48px);
}