@import 'src/resources/styles';

.sizes {
  width: 100%;
    
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 44px;

  @media screen and (max-width: $size-3-column-width) {
    gap: 20px;
  }
}

.groups {
  display: flex;
  flex-direction: column;
  gap: 64px;
}