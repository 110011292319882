@import 'src/resources/styles';

.wrap {
  width: 100%;
  
  .advantages {
    width: 100%;
    max-width: 1200px;
    margin-left: 50px;

    padding-top: 52px;
    padding-bottom: 24px;
    border-bottom: 2px solid $c-gray-1;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;

    .item {
      display: flex;
      align-items: center;
      gap: 32px;

      .icon {
        @include square-icon (100px, $c-gray-2);
      }

      .content {
        max-width: 380px;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 8px;

        .title {
          @include font-base (36px, 700, 100%, 0.05em, uppercase);
          color: $c-highlight-red;
        }

        .description {
          @include font-base (18px, 500, 120%, 0.05em, none);
          text-align: justify;
          color: $c-black;
        }
      }
    }
  }

  .questions {
    width: 100%;
    max-width: 1200px;
    margin-top: 52px;
    margin-left: 50px;
    
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    column-gap: 24px;
    row-gap: 36px;
    
    .item {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      .icon {
        @include square-icon (24px, $c-black);
      }

      .content {
        width: 100%;
        max-width: 480px;

        display: flex;
        flex-direction: column;
        gap: 12px;

        .title {
          @include font-base (18px, 600, 100%, 0.05em, none);
          color: $c-black;
        }

        .description {
          @include font-base (16px, 500, 110%, 0.05em, none);
          text-align: justify;
          color: $c-gray-4;

          a {
            font-weight: 600;
            color: $c-highlight-red;
          }
        }
      }
    }
  }

  @media screen and (max-width: $size-4-column-width) {
    .advantages, .questions {
      margin-left: 0;
      padding-left: 12px;
      padding-right: 12px;
    }

    .advantages .item {
      svg {
        @include square-icon (72px, $c-gray-2);
      }

      .content {
        .title {
          font-size: 28px;
        }

        .description {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 1140px) {
    .advantages,
    .questions {
      padding-bottom: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: none;

      .item {
        max-width: 640px;

        .content {
          max-width: none;
        }
      }
    }
  }

  @media screen and (max-width: 610px) {
    .advantages,
    .questions {
      padding-left: 0;
      padding-right: 0;
    }

    .advantages {
      padding-bottom: 0;

      .item {
        align-items: flex-start;
  
        .icon {
          @include square-icon (44px, $c-gray-2);
        }
  
        .content .title {
          font-size: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 470px) {
    .advantages .item {
      gap: 12px;

      .icon {
        @include square-icon (24px, $c-gray-2);
      }
    }
  }
}