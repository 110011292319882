@import 'src/resources/styles';

.wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  gap: 16px;

  @include hover;

  .imgWrap {
    width: 100%;
    max-height: 260px;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    .img {
      object-fit: contain;
      max-width: 90%;
      max-height: 260px;
    }
  }

  .content {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr auto;
    align-items: end;
    gap: 12px;

    .textWrap {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    .subtitle {
        @include font-base (14px, 500, 100%, 0, uppercase);
        color: $c-accent-4;
      }

    .arrowIcon {
      @include square-icon (32px, $c-black);
      @include hover;
    }
  }

  &:hover {
    .arrowIcon {
      @include square-icon (32px, $c-accent-4);
      transform: translateX(-4px);
    }
  }
}