@import 'src/resources/styles';

.loader {
  height: 80vh;
}

.pageSection {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.navigateBtn {
  width: auto;
  height: auto;
  padding: 0;

  background: inherit;
  border: none;

  .icon {
    @include square-icon (24px, rgba($c-black, 0.8));
    @include hover;
  }

  &:hover .icon {
    @include square-icon (24px, $c-black);
  }
}

.wrap {
  padding-top: 44px;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  gap: 44px;

  .imageArea {
    max-width: 900px;
    position: sticky;
    z-index: $zi-fixed-elements;
    top: 112px;

    .image {
      max-width: 900px;
      max-height: 80vh;
      width: 100%;
      border-radius: 16px;
    }

    .marks {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      .mark {
        position: absolute;
        width: 24px;
        height: 24px;
        padding: 3px;

        border: none;
        border-radius: 50%;
        background: $c-white;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
        transform: translate(-50%, -50%);
        @include hover;

        div {
          width: 100%;
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 50%;
          background: $c-white;
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
          @include hover;

          .icon {
            @include square-icon (18px, $c-white);
          }
        }

        &.active {
          width: 32px;
          height: 32px;
          padding: 4px;

          div {
            background: $c-highlight-red;
          }
        }
      }
    }
  }

  .contentArea {
    align-self: start;
    min-width: 260px;
    padding-bottom: 128px;
    display: flex;
    flex-direction: column;
    gap: 44px;

    .list {
      padding-top: 12px;
      padding-bottom: 64px;

      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      align-items: stretch;
      gap: 24px 24px;

      .item {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        gap: 12px;

        .groupLink {
          width: fit-content;
          padding: 4px 12px;

          display: flex;
          align-items: center;
          gap: 6px;

          border-radius: 12px;
          background: $c-accent-2;

          .text {
            @include font-base (12px, 600, 100%, 0.05em, uppercase);
            color: $c-accent-4;
          }

          .icon {
            @include square-icon (18px, $c-accent-4);
          }
        }
  
        .productCard {
          padding: 12px;
          border: 1px dashed $c-accent-3;
          border-radius: 16px;
  
          img {
            max-height: 200px !important;
            padding-bottom: 12px !important;
          }
  
          &:hover {
            border-color: $c-accent-4;
          }

          &.active {
            background: rgba($c-highlight-red, 0.1) !important;
          } 
        }

        .cardWrap {
          position: relative;
          display: grid;
          grid-template-rows: 1fr;

          .checked {
            visibility: hidden;
            position: absolute;
            top: -6px;
            right: -6px;
            width: 36px;
            height: 36px;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 50%;
            background: $c-highlight-red;
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);

            .icon {
              @include square-icon (28px, $c-white);
            }

            &.active {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .wrap {
    padding-top: 24px;
  }

  .container {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    justify-content: start;
    align-items: start;
    gap: 24px;

    .imageArea {
      position: relative;
      top: 0;
      width: fit-content;

      .imgContainer {
        width: fit-content;

        .image {
          height: auto;
          max-width: 100%;
          width: auto;
          object-fit: contain;
        }
      }
    }
  }
}