@import 'src/resources/styles';

.wrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .sectionHeader {
    display: grid;
    grid-template-columns: 190px 1fr;

    .orderNumber {
      line-height: 85%;
      color: $c-accent-2;
    }

    .titleWrap {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        line-height: 100%;
      }

      .subtitle {
        @include font-base (18px, 500, 90%, 0.05em, none);
        color: $c-accent-4;

        @media screen and (max-width: $size-3-column-width) {
          font-size: 14px;
        }
      }
    }
  }

  .sectionBody {
    padding-left: 190px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    .previewRow {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 64px;
      
      & > * {
        margin: 20px 0;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      .iconShowPreview {
        transform: rotate(90deg);
      }
    }
  }

  @media screen and (max-width: $size-3-column-width) {
    .sectionBody { 
      .previewRow {
      grid-template-columns: repeat(2, 1fr);

        & > *:nth-child(3) {
          display: none;
        }
      } 
    }
  }

  @media screen and (max-width: calc($size-3-column-width)) {
    .sectionHeader { 
      grid-template-columns: 140px 1fr; 
    }
    
    .sectionBody { 
      padding-left: 140px; 
    }
  }

  @media screen and (max-width: $size-2-column-width) {
    .sectionHeader { 
      grid-template-columns: auto 1fr; 
      
      .orderNumber {
        padding-right: 20px;
      }
    }

    .sectionBody {
      padding-left: 0;
    
      .previewRow, .showPreviewButton { 
        display: none; 
      }
    }
  }
}

