@import 'src/resources/styles';

@mixin fontsResize ($name, $desc, $error, $input, $icon) {
  .wrap {
    .name {
      font-size: $name;
      .icon { @include square-icon ($icon, $c-highlight-red); }
    }
    .description { font-size: $desc; }
    .error { font-size: $error; }
    .input, .input::placeholder { font-size: $input; }
  }
}

.wrap {
  display: flex;
  flex-direction: column;

  .name {
    padding-left: 6px;
    padding-bottom: 8px;

    display: flex;
    align-items: flex-start;
    gap: 8px;

    @include font-base (24px, 700, 100%, 0.05em, uppercase);
    color: $c-black;

    .icon {
      margin-top: 3px;
      @include square-icon (16px, $c-highlight-red);
    }
  }

  .description {
    padding-left: 6px;
    padding-bottom: 10px;

    @include font-base (16px, 500, 100%, 0.05em, none);
    color: $c-accent-4;
  }

  .error {
    padding-left: 6px;
    padding-top: 12px;

    @include font-base (14px, 500, 100%, 0.05em, none);
    color: $c-highlight-red;
  }

  .input {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 120px;
    height: auto;
    margin-top: 6px;
    padding: 12px;

    display: flex;
    align-items: center;

    border: 1px solid $c-gray-2;
    border-radius: 12px;
    outline: none !important;

    @include font-base (16px, 500, 130%, 0.1em, none);
    color: $c-black;

    &::placeholder {
      @include font-base (16px, 500, 90%, 0.1em, none);
      color: $c-gray-2;
    }
  }
}

@media screen and (max-width: $size-4-column-width) {
  @include fontsResize(20px, 16px, 14px, 16px, 14px);
}

@media screen and (max-width: $size-3-column-width) {
  @include fontsResize(16px, 14px, 12px, 16px, 12px);
}

@media screen and (max-width: $size-2-column-width) {
  @include fontsResize(16px, 12px, 12px, 14px, 10px);
}