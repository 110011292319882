@import 'src/resources/styles';

.wrap {
  width: 100%;
  margin-top: 80px;
  padding: 0 $size-min-screen-border-gap;
  padding-top: 100px;
  padding-bottom: 128px;
  display: flex;
  justify-content: center;
  background: $c-black;

  .container {
    width: 100%;
    max-width: $size-container-width;

    display: flex;
    justify-content: space-between;

    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .shopInfo {
        display: flex;
        align-items: center;
        gap: 44px;

        .logo {
          @include font-additional (44px, 600, 100%, 0.05em, uppercase);
          color: $c-white;
        }

        .description {
          max-width: 355px;
          @include font-base (16px, 400, 110%, 0.05em, none);
          color: $c-white;
        }
      }

      .navigation {
        display: flex;
        align-items: center;
        gap: 12px;

        a {
          display: block;
          padding: 8px 24px;

          border: 1px solid $c-white;
          border-radius: 16px;

          @include font-base (16px, 500, 90%, 0.05em, none);
          white-space: no-wrap;
          text-align: center;
          color: $c-white;

          @include hover;

          &:hover {
            border-color: $c-accent-3;
            color: $c-accent-3;
          }
        }
      }
    }

    .buttons {
      display: flex;
      align-items: flex-end;
      gap: 24px;

      a {
        display: block;
        padding: 12px;
        padding-bottom: 16px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        border: 1px solid $c-white;
        border-radius: 16px;
        @include hover;

        .icon {
          @include square-icon (44px, $c-white);
        }

        .text {
          @include font-base (16px, 500, 90%, 0.05em, none);
          color: $c-white;
          @include hover;
        }

        &:hover {
          border-color: $c-accent-3;
          .icon path { fill: $c-accent-3; }
          .text { color: $c-accent-3; }
        }
      }
    }
  }

  @media screen and (max-width: 950px) {
    padding-top: 80px;
    padding-bottom: 120px;

    .container {
      .content {
        .shopInfo {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .description {
            font-size: 14px;
          }
        }
        .navigation {
          display: grid;
          grid-template-columns: repeat(2, 200px);
        }
      }
      .buttons {
        flex-direction: column;
        justify-content: flex-end;
        gap: 16px;

        a {
          .icon {
            @include square-icon (32px, $c-white);
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .container {
      flex-direction: column;

      .content {
        .shopInfo {
          flex-direction: row;
          gap: 24px;

          .logo { 
            font-size: 32px; 
          }
          
          .description {
            max-width: 100%; 
          } 
        }
        
        .navigation {
          grid-template-columns: 1fr 1fr;
        }
      }

      .buttons {
        padding-top: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media screen and (max-width: 420px) {
    padding-bottom: 80px;
    
    .container .content .shopInfo {
      flex-direction: column;
      gap: 8px;
    }
  }

  @media screen and (max-width: 380px) {
    .container .content .navigation,
    .container .buttons {
      grid-template-columns: 1fr;
    }
  }
}