@import 'src/resources/styles';

.singleImage {
  max-width: 350px;
  max-height: 350px;
  object-fit: contain;
}

.wrap {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  transition: all 0.3s linear;

  .image {
    max-width: 350px;
    max-height: 350px;
    object-fit: contain;
  }

  .controlBar {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .counter {
      @include font-base (20px, 400, 130%, 0.05em, uppercase);
      color: $c-accent-4;
    }

    button {
      background: inherit;
      border: none;
      @include hover;

      .icon {
        @include square-icon (64px, $c-accent-3);
        
        path {
          transition: all 0.2s ease;
        }

        &.left {
          transform: rotate(180deg);
        }
      }

      &:hover {
        .icon {
          @include square-icon (64px, $c-accent-4);
        }
      }

      &:disabled {
        .icon {
          @include square-icon (64px, $c-gray-2);
        }

        cursor: default;
      }
    }
  }
}