@import 'src/resources/styles';

.wrap {
  position: fixed;
  top: 90px;
  left: 50%;
  
  display: inline-flex;
  align-items: flex-start;
  gap: 16px;
  
  padding: 6px 12px;
  padding-right: 16px;
  border-radius: 12px;
  background: $c-accent-2;
  
  transition: all 0.2s ease-out;
  z-index: $zi-tooltip;

  svg {
    @include square-icon (20px, $c-accent-4);
  }

  p {
    padding-top: 3px;
    @include font-base (14px, 500, 100%, 0.07em, none);
    color: $c-black;
  }

  &.hidden {
    opacity: 0;
    transform: translate(-50%, -100px);
  }

  &.view {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}